import { toast } from "react-toastify";
import APIs from "../apiUrl";

const getSponsor = async () => {
  try {
    const response = await APIs.get(`/all_sponsor`);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const SponsorServices = {
  getSponsor,
};

export default SponsorServices;
