import React from "react";
import { InfinitySpin, ThreeDots } from "react-loader-spinner";

const ThreeLoader = () => {
  return (
    <ThreeDots
      height="80"
      width="80"
      radius="9"
      color="#ffffff"
      ariaLabel="three-dots-loading"
      wrapperStyle={{}}
      wrapperClassName=""
      visible={true}
    />
  );
};

export default ThreeLoader;

export const InfinitySpinner = () => {
  return <InfinitySpin width="100" color="#bf0a30" />;
};

export const ThreeLoaderAppColor = () => {
  return (
    <ThreeDots
      height="40"
      width="40"
      radius="9"
      color="#bf0a30"
      ariaLabel="three-dots-loading"
      wrapperStyle={{}}
      wrapperClassName=""
      visible={true}
    />
  );
};
