import { createAsyncThunk } from "@reduxjs/toolkit";
import TeamServices from "./teamServices";
import { toast } from "react-toastify";

export const GetTeam = createAsyncThunk("team/getTeam", async (payload) => {
  try {
    let response = await TeamServices.getTeam(payload);

    return response;
  } catch (error) {
    toast(error.message);
    throw error;
  }
});
