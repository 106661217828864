import { createAsyncThunk } from "@reduxjs/toolkit";
import GalleryServices from "./galleryServices";
import { toast } from "react-toastify";

export const GetGallery = createAsyncThunk(
  "gallery/getGallery",
  async (payload) => {
    try {
      let response = await GalleryServices.getGallery(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetGalleryAdv = createAsyncThunk(
  "gallery/getSponsorsadv",
  async (payload) => {
    try {
      let response = await GalleryServices.getGallery(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);
