import APIs from "../apiUrl";

const getSocial = async () => {
  try {
    const response = await APIs.get("/get_socials_details");

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const SocialSevices = { getSocial };

export default SocialSevices;
