import React, { useContext, useState } from "react";
import { StylesSideBar } from "./styles.side";
import Logo from "../../Assets/health_png.png";
import { FiChevronRight, FiX } from "react-icons/fi";
import { NavLink, useNavigate } from "react-router-dom";
import { NavContext } from "../../App";

function SideBard() {
  const { setOpenSideBar } = useContext(NavContext);

  const [select, setSelect] = useState("");

  const navigate = useNavigate();

  const handleSelect = (item, route) => {
    setSelect(item);

    if (route) {
      navigate(route);

      setOpenSideBar(false);
    }
  };

  return (
    <StylesSideBar>
      <div className="content">
        <div className="topSection">
          <img src={Logo} />

          <div
            className="topSection_icon_body"
            onClick={() => setOpenSideBar(false)}
          >
            <FiX className="topSection_icon" />
          </div>
        </div>

        <div className="bottomSection">
          <div
            className="navContent"
            onClick={() => {
              handleSelect("", "/");
            }}
          >
            <NavLink className={"navItem"}>Home</NavLink>
          </div>

          <div className="navContent_dropDown">
            <div
              className={"navItem_container"}
              onClick={() => {
                if (select) {
                  handleSelect("");
                } else {
                  handleSelect("about");
                }
              }}
            >
              <p>About</p>

              <div className={"navItem_container_icon_body"}>
                <FiChevronRight className={"navItem_container_icon"} />
              </div>
            </div>

            {select === "about" && (
              <div className="navContent_dropDown_item">
                <div
                  className="navContent"
                  onClick={() => handleSelect("", "/about")}
                >
                  <NavLink className={"navItem"}>About</NavLink>
                </div>

                <div
                  className="navContent"
                  onClick={() => handleSelect("", "/team")}
                >
                  <NavLink className={"navItem"}>Team</NavLink>
                </div>

                <div
                  className="navContent"
                  onClick={() => handleSelect("", "/volunteer")}
                >
                  <NavLink className={"navItem"}>Volunteer</NavLink>
                </div>
              </div>
            )}
          </div>

          <div className="navContent_dropDown">
            <div
              className={"navItem_container"}
              onClick={() => {
                if (select) {
                  handleSelect("");
                } else {
                  handleSelect("service");
                }
              }}
            >
              <p>Service</p>

              <div className={"navItem_container_icon_body"}>
                <FiChevronRight className={"navItem_container_icon"} />
              </div>
            </div>

            {select === "service" && (
              <div className="navContent_dropDown_item">
                <div
                  className="navContent"
                  onClick={() => handleSelect("", "/services")}
                >
                  <NavLink className={"navItem"}>Services</NavLink>
                </div>

                <div
                  className="navContent"
                  onClick={() => handleSelect("", "/eventDetails")}
                >
                  <NavLink className={"navItem"}>
                    Human Right & Advocacy
                  </NavLink>
                </div>

                <div
                  className="navContent"
                  onClick={() => handleSelect("", "/eventDetails")}
                >
                  <NavLink className={"navItem"}>
                    Biomedical & Behavioral Research
                  </NavLink>
                </div>

                <div
                  className="navContent"
                  onClick={() => handleSelect("", "/eventDetails")}
                >
                  <NavLink className={"navItem"}>Care & Support</NavLink>
                </div>

                <div
                  className="navContent"
                  onClick={() => handleSelect("", "/eventDetails")}
                >
                  <NavLink className={"navItem"}>
                    Sexual Health Programs
                  </NavLink>
                </div>

                <div
                  className="navContent"
                  onClick={() => handleSelect("", "/eventDetails")}
                >
                  <NavLink className={"navItem"}>Mental Health</NavLink>
                </div>
              </div>
            )}
          </div>

          <div className="navContent_dropDown">
            <div
              className={"navItem_container"}
              onClick={() => {
                if (select) {
                  handleSelect("");
                } else {
                  handleSelect("project");
                }
              }}
            >
              <p>InnoSphere</p>

              <div className={"navItem_container_icon_body"}>
                <FiChevronRight className={"navItem_container_icon"} />
              </div>
            </div>

            {select === "project" && (
              <div className="navContent_dropDown_item">
                <div
                  className="navContent"
                  onClick={() => handleSelect("", "/project")}
                >
                  <NavLink className={"navItem"}>Project</NavLink>
                </div>

                <div
                  className="navContent"
                  onClick={() => handleSelect("", "/resourse")}
                >
                  <NavLink className={"navItem"}>Resources</NavLink>
                </div>
              </div>
            )}
          </div>

          <div
            className="navContent"
            onClick={() => {
              handleSelect("", "/gallery");
            }}
          >
            <NavLink className={"navItem"}>Gallery</NavLink>
          </div>

          <div
            className="navContent"
            onClick={() => {
              handleSelect("", "/blog");
            }}
          >
            <NavLink className={"navItem"}>Blog</NavLink>
          </div>

          <div
            className="navContent"
            onClick={() => {
              handleSelect("", "/contact");
            }}
          >
            <NavLink className={"navItem"}>Contact</NavLink>
          </div>

          <div
            className="navContent"
            onClick={() => {
              handleSelect("", "/donate");
            }}
          >
            <NavLink className={"navItem"}>Donate</NavLink>
          </div>
        </div>
      </div>
    </StylesSideBar>
  );
}

export default SideBard;
