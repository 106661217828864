import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { GetProject } from "../features/project/projectServicesSync";
import { reset, updateProjectPage } from "../features/project/projectSlice";
import LoaderScreen from "../utils/loaderScreen";
import moment from "moment";
import { DomPaginationState } from "../Components/DOM_Pagination_State";
import { NotFound } from "./Styles/notFound";

function ProjectPage() {
  const dispatch = useDispatch();

  const { projectArticleDetails, projectLoading, projectlimit, projectPage } =
    useSelector((state) => state.project);

  useEffect(() => {
    dispatch(GetProject({ page: projectPage, limit: projectlimit }));

    return () => {
      dispatch(reset());
    };
  }, []);

  console.log(projectArticleDetails, "fineign");

  const nextPage = () => {
    if (!projectLoading) {
      if (projectArticleDetails?.hasNextPage) {
        dispatch(updateProjectPage(projectPage + 1));

        dispatch(
          GetProject({
            page: projectPage + 1,
            limit: projectlimit,
          })
        );
      }
    }
  };

  const prevPage = () => {
    if (!projectLoading) {
      if (projectArticleDetails?.hasPrevPage) {
        dispatch(updateProjectPage(projectPage - 1));

        dispatch(
          GetProject({
            page: projectPage - 1,
            limit: projectlimit,
          })
        );
      }
    }
  };

  return (
    <>
      <section class="page-header">
        <div
          class="page-header-bg"
          style={{
            backgroundImage:
              "url(assets/images/backgrounds/page-header-bg.jpg)",
          }}
        ></div>
        <div class="container">
          <div class="page-header__inner">
            <ul class="thm-breadcrumb list-unstyled">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>
                <span>/</span>
              </li>
              <li class="active">Projects</li>
            </ul>
            <h2>Projects</h2>
          </div>
        </div>
      </section>
      {projectLoading ? (
        <LoaderScreen />
      ) : (
        <section class="events-page">
          <div class="container">
            <div class="row">
              {projectArticleDetails?.docs?.length > 0 ? (
                projectArticleDetails?.docs?.map((item, key) => (
                  <div class="col-xl-4 col-lg-6 col-md-6" key={key}>
                    <div class="events-one__single">
                      <div class="events-one__img">
                        <img
                          src={item?.cover_pic}
                          alt=""
                          style={{ objectFit: "cover", height: "500px" }}
                        />
                        <div class="events-one__date">
                          <p>{`${moment(item?.date).format(
                            "DD MMM, YYYY"
                          )}`}</p>
                        </div>
                        <div class="events-one__content">
                          <ul class="list-unstyled events-one__meta">
                            <li>
                              <i class="fas fa-clock"></i>
                              {`${moment(item?.date).format("h:mma")}`}
                            </li>
                            <li>
                              <i class="fas fa-map-marker-alt"></i>
                              {item?.location?.slice(0, 14)}
                            </li>
                          </ul>
                          <h3
                            class="events-one__title"
                            style={{
                              overflow: "hidden",
                              whiteSpace: "wrap",
                              maxHeight: "2rem",
                              lineClamp: 1,
                              textOverflow: "ellipsis",
                            }}
                          >
                            <NavLink to={`/project/${item?._id}`}>
                              {item?.title}
                            </NavLink>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <NotFound>
                  <div className="not_found_wrapper">
                    <h3 class="team-one__name">
                      <a href="team-details.html" style={{ color: "#283734" }}>
                        No Project found
                      </a>
                    </h3>
                  </div>
                </NotFound>
              )}

              {projectArticleDetails?.docs?.length > 0 && (
                <DomPaginationState
                  onNext={nextPage}
                  onPrev={prevPage}
                  skip={projectArticleDetails?.page}
                  loading={projectLoading}
                  hasNext={projectArticleDetails?.hasNextPage}
                  hasPrev={projectArticleDetails?.hasPrevPage}
                />
              )}
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default ProjectPage;
