import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import {
  PaginationDomDiv,
  PaginationMobile,
} from "./styles/paginationStyles.style";
import { InfinitySpin } from "react-loader-spinner";
import useWindowDimensions from "../../hooks/useWindowDimension";

const DomPaginationState = ({
  onPrev,
  onNext,
  skip,
  loading,
  hasNext,
  hasPrev,
}) => {
  const { width } = useWindowDimensions();
  return width <= 650 ? (
    <PaginationMobile className="__mobile__pagination">
      <div
        className={`__left ${!hasPrev && "disabled"}`}
        onClick={() => onPrev()}
      >
        <AiOutlineArrowLeft size={20} />
      </div>
      <span className="__current">Page {skip}</span>
      <div
        className={`__right ${!hasNext && "disabled"}`}
        onClick={() => onNext()}
      >
        <AiOutlineArrowRight size={20} />
      </div>
    </PaginationMobile>
  ) : (
    <PaginationDomDiv className="notification__paginationWrapper">
      <div className="newTable__paginationNumber">Page {skip}</div>
      <div className="newTable__paginationNavigation">
        {!loading ? (
          <button
            className={`newTable__paginationNavigation__button ${
              !hasPrev && "disabled"
            }`}
            onClick={() => onPrev()}
          >
            previous
          </button>
        ) : (
          <InfinitySpin width="100" color="#4fa94d" />
        )}

        {!loading ? (
          <button
            className={`newTable__paginationNavigation__button ${
              !hasNext && "disabled"
            }`}
            onClick={() => onNext()}
          >
            next
          </button>
        ) : (
          <InfinitySpin width="100" color="#4fa94d" />
        )}
      </div>
    </PaginationDomDiv>
  );
};

export { DomPaginationState };
