import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import ResourceServices from "./resourcesService";

export const GetResource = createAsyncThunk(
  "resource/getResource",
  async (payload) => {
    try {
      let response = await ResourceServices.getAlluserResource(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetSingleResource = createAsyncThunk(
  "resource/getSingleResource",
  async (payload) => {
    try {
      let response = await ResourceServices.getSingleResource(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);
