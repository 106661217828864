import { toast } from 'react-toastify';
import APIs from '../apiUrl';

const testimonyImageUpload = async (payload) => {
  try {
    const response = await APIs.post('/uploadfiles', payload);

    if (response?.data?.message === 'success') {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const CreateTestimony = async (payload) => {
  try {
    const response = await APIs.post('/create_testimony', payload);

    if (response?.data?.message === 'success') {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getTestimonyList = async (payload) => {
  try {
    console.log(payload, 'season of work');

    const response = await APIs.get(
      `/get_enduser_testimony/${payload.page}/${payload.limit}`
    );

    if (response?.data?.message === 'success') {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const testimonyServices = {
  getTestimonyList,
  CreateTestimony,
  testimonyImageUpload,
};

export default testimonyServices;
