import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  reset,
  updateVolunteerPage,
} from '../features/volunteers/volunteersSlices';
import { GetVolunteers } from '../features/volunteers/volunteersServicesSync';
import LoaderScreen from '../utils/loaderScreen';
import { DomPaginationState } from '../Components/DOM_Pagination_State';
import { NotFound } from './Styles/notFound';

function Volunteer() {
  const dispatch = useDispatch();

  const {
    volunteerArticleDetails,
    volunteerLoading,
    volunteerlimit,
    volunteerPage,
  } = useSelector((state) => state.volunteer);

  useEffect(() => {
    dispatch(GetVolunteers({ page: volunteerPage, limit: volunteerlimit }));

    return () => {
      dispatch(reset());
    };
  }, []);

  const nextPage = () => {
    if (!volunteerLoading) {
      if (volunteerArticleDetails?.hasNextPage) {
        dispatch(updateVolunteerPage(volunteerPage + 1));

        dispatch(
          GetVolunteers({
            page: volunteerPage + 1,
            limit: volunteerlimit,
          })
        );
      }
    }
  };

  const prevPage = () => {
    if (!volunteerLoading) {
      if (volunteerArticleDetails?.hasPrevPage) {
        dispatch(updateVolunteerPage(volunteerPage - 1));

        dispatch(
          GetVolunteers({
            page: volunteerPage - 1,
            limit: volunteerlimit,
          })
        );
      }
    }
  };

  return (
    <>
      <div class="stricky-header stricked-menu main-menu">
        <div class="sticky-header__content"></div>
      </div>

      <section class="page-header">
        <div
          class="page-header-bg"
          style={{
            backgroundImage:
              'url(assets/images/backgrounds/page-header-bg.jpg)',
          }}
        ></div>
        <div class="container">
          <div class="page-header__inner">
            <ul class="thm-breadcrumb list-unstyled">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>
                <span>/</span>
              </li>
              <li class="active">Volunteers</li>
            </ul>
            <h2>Volunteers</h2>
          </div>
        </div>
      </section>

      <section class="team-one">
        <div class="container">
          <div class="row">
            {volunteerLoading ? (
              <LoaderScreen />
            ) : volunteerArticleDetails?.docs?.length > 0 ? (
              volunteerArticleDetails?.docs?.map((item, key) => (
                <div
                  class="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
                  data-wow-delay="100ms"
                >
                  <div class="team-one__single">
                    <div class="team-one__img">
                      <img
                        src={item?.cover_pic}
                        alt=""
                        className="teamImage"
                        style={{ objectFit: 'cover' }}
                      />

                      {/* <LazyLoadImage
                      src={item?.cover_pic}
                      alt=""
                      className="teamImage"
                      style={{ objectFit: "cover" }}
                      visibleByDefault={true}
                    /> */}

                      {/* <Img
                      cloudName={"cloud"}
                      imageName={item?.item?.cover_pic}
                      fluid={{
                        maxWidth: 300,
                        height: 300,
                      }}
                      style={{
                        width: "40vw",
                        height: "20vh",
                      }}
                      urlParams={item?.cover_pic}
                    /> */}

                      <div class="team-one__social">
                        <a href={`${item?.facebook}`}>
                          <i class="fab fa-facebook"></i>
                        </a>
                        <a href={`${item?.twitter}`}>
                          <i class="fab fa-twitter"></i>
                        </a>
                        <a href={`${item?.linkedln}`}>
                          <i class="fab fa-pinterest-p"></i>
                        </a>
                        <a href={`${item?.instagram}`}>
                          <i class="fab fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                    <div class="team-one__content">
                      <h3 class="team-one__name">
                        <a href="javascript:void(0);">{`${item?.firstname} ${item?.lastname}`}</a>
                      </h3>
                      <p class="team-one__sub-title">{`${item?.office}`}</p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <NotFound>
                <div className="not_found_wrapper">
                  <h3 class="team-one__name">
                    <a href="team-details.html" style={{ color: '#283734' }}>
                      No Volunteer found
                    </a>
                  </h3>
                </div>
              </NotFound>
            )}

            {volunteerArticleDetails?.docs?.length > 0 && (
              <DomPaginationState
                onNext={nextPage}
                onPrev={prevPage}
                skip={volunteerArticleDetails?.page}
                loading={volunteerLoading}
                hasNext={volunteerArticleDetails?.hasNextPage}
                hasPrev={volunteerArticleDetails?.hasPrevPage}
              />
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default Volunteer;
