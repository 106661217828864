import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import BlogServices from "./blogServices";

export const GetBlog = createAsyncThunk("blog/getBlog", async (payload) => {
  try {
    let response = await BlogServices.getAlluserBlog(payload);

    return response;
  } catch (error) {
    toast(error.message);
    throw error;
  }
});

export const GetSingleBlog = createAsyncThunk(
  "blog/getSingleBlog",
  async (payload) => {
    try {
      let response = await Promise.all([
        BlogServices.getSingleBlog(payload),
        BlogServices.getBloggerComment(payload),
      ])
        .then((data) => {
          return data;
        })
        .catch((err) => {
          throw err;
        });

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const PostComment = createAsyncThunk(
  "blog/postComment",
  async (payload) => {
    try {
      let response = await BlogServices.createComment(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);
