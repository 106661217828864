import { createSlice } from "@reduxjs/toolkit";

import { CreateSocial, GetSocial } from "./socialServicesSync";

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  socialMediaDetails: null,
  socialLoader: false,
};

export const socialSlice = createSlice({
  name: "social",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.message = "";
      state.socialLoader = false;
    },
  },
  extraReducers: {
    [GetSocial.pending]: (state, action) => {
      state.isLoading = true;
    },

    [GetSocial.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.message = action.payload?.message;
      state.socialMediaDetails = action.payload?.data;
    },

    [GetSocial.rejected]: (state, action) => {
      state.isLoading = false;
      state.message = action.payload?.message;
    },
  },
});

export const { reset } = socialSlice.actions;
export default socialSlice.reducer;
