import { configureStore } from '@reduxjs/toolkit';
import DonationReducer from '../features/donation/donationSlices';
import CharityReducer from '../features/charity/charitySlice';
import ProjectReducer from '../features/project/projectSlice';
import ResourcseReducer from '../features/resourse/resourcesSlices';
import ServicesReducer from '../features/services/servicesSlices';
import BlogReducer from '../features/blog/blogSlices';
import TeamReducer from '../features/team/teamSlices';
import VolunteerReducers from '../features/volunteers/volunteersSlices';
import TestimonyReducers from '../features/testimony/testimonySlices';
import GalleryReducer from '../features/gallery/gallerySlices';
import SponsorReducer from '../features/sponsor/sponsorSlices';
import SocialReducer from '../features/social/socialSlices';
import faqReducer from '../features/faq/faqSlices';

export const store = configureStore({
  reducer: {
    donation: DonationReducer,
    charity: CharityReducer,
    project: ProjectReducer,
    resource: ResourcseReducer,
    services: ServicesReducer,
    blog: BlogReducer,
    team: TeamReducer,
    volunteer: VolunteerReducers,
    testimony: TestimonyReducers,
    gallery: GalleryReducer,
    sponsor: SponsorReducer,
    social: SocialReducer,
    faq: faqReducer,
  },
});
