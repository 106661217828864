import styled from "styled-components";

export const TeamDiv = styled.div`
  .teamImage {
    height: 373px;

    @media screen and (max-width: 1199px) {
      height: 600px;
    }

    @media screen and (max-width: 992px) {
      height: 450px;
    }

    @media screen and (max-width: 768px) {
      height: 700px;
    }

    @media screen and (max-width: 500px) {
      height: 500px;
    }

    @media screen and (max-width: 420px) {
      height: 373px;
    }
  }
`;
