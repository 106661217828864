import styled from "styled-components";

export const StylesSideBar = styled.div`
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #00000046;
  z-index: 2000;

  .content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 40%;
    background-color: #283734;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .topSection {
      position: relative;
      justify-content: center;
      align-items: center;
      display: flex;
      width: 100%;

      img {
        width: 90px;

        height: 90px;
      }

      .topSection_icon_body {
        position: absolute;
        top: 0;
        right: 0;
        padding: 15px;
        margin: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .topSection_icon {
          font-size: 24px;
          color: #ffffff;
        }
      }
    }

    .bottomSection {
      width: 100%;
      border-top: 1px solid #33413e;
      border-bottom: 1px solid #33413e;

      .navContent {
        /* border-top: 1px solid #33413e; */
        border-bottom: 1px solid #33413e;
        width: 100%;
        padding: 10px 20px;
        cursor: pointer;

        .navItem {
          color: #ffffff;
          font-size: 14px;
          line-height: 34px;
          font-weight: 500;
          width: 100%;
        }
      }

      .navContent_dropDown {
        position: relative;

        .navItem_container {
          border-bottom: 1px solid #33413e;
          width: 100%;
          padding: 10px 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;

          p {
            color: #ffffff;
            font-size: 14px;
            line-height: 34px;
            font-weight: 500;
          }

          .navItem_container_icon_body {
            display: flex;
            align-items: center;
            justify-content: center;

            .navItem_container_icon {
              font-size: 24px;
              color: #ffffff;
            }
          }
        }

        .navContent_dropDown_item {
          padding: 10px 20px;
        }
      }
    }
  }
`;
