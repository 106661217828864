import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import CharityServices from "./charityServices";

export const FetchingCharity = createAsyncThunk(
  "charity/initialize_donation",
  async (payload) => {
    try {
      let response = await CharityServices.FetchingCharitys(payload.value);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetSingleCharity = createAsyncThunk(
  "charity/getSingleCharity",
  async (payload) => {
    try {
      let response = await CharityServices.getSingleCharity(payload);

      return response;
    } catch (error) {
      toast(error.message);

      // Navigate to the URL
      window.location.href = "/donate";

      throw error;
    }
  }
);
