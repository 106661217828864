import APIs from "../apiUrl";

const getAllProjects = async (payload) => {
  try {
    const response = await APIs.get(`/get_ministries/${payload}`);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getAlluserProjects = async (payload) => {
  try {
    const response = await APIs.get(
      `/get_enduser_ministries/${payload.page}/${payload.limit}`
    );

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getSingleProject = async (payload) => {
  try {
    const response = await APIs.get(`/get_single_ministry/${payload}`);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const ProjectServices = {
  getAllProjects,
  getSingleProject,
  getAlluserProjects,
};

export default ProjectServices;
