import { createSlice } from '@reduxjs/toolkit';
import { GetProject, GetSingleProject } from './projectServicesSync';

const initialState = {
  isError: false,
  isSuccess: false,
  message: '',
  projectLoading: false,
  singleProjectDetails: {},
  projectArticleDetails: {},
  projectlimit: 9,
  projectPage: 1,
};

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
      state.projectLoading = false;
      state.singleProjectDetails = {};
      state.projectArticleDetails = {};
      state.projectlimit = 9;
      state.projectPage = 1;
    },

    updateProjectPage: (state, action) => {
      state.projectPage = action.payload;
    },
  },
  extraReducers: {
    [GetProject.pending]: (state, action) => {
      if (state?.projectArticleDetails?.docs?.length <= 0) {
        state.projectLoading = true;
      }
    },
    [GetProject.fulfilled]: (state, action) => {
      state.projectLoading = false;
      state.message = action.payload?.message;
      state.projectArticleDetails = action?.payload?.data;
    },
    [GetProject.rejected]: (state, action) => {
      state.projectLoading = false;
      state.message = action.payload?.message;
    },

    [GetSingleProject.pending]: (state, action) => {
      state.projectLoading = true;
    },
    [GetSingleProject.fulfilled]: (state, action) => {
      state.projectLoading = false;
      state.message = action.payload?.message;
      state.singleProjectDetails = action.payload?.data;
    },
    [GetSingleProject.rejected]: (state, action) => {
      state.projectLoading = false;
      state.message = action.payload?.message;
    },
  },
});

export const { reset, updateProjectPage } = projectSlice.actions;

export default projectSlice.reducer;
