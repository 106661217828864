import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { FetchingCharity } from '../features/charity/charityServiceSync';
import { useDispatch, useSelector } from 'react-redux';
import { reset } from '../features/charity/charitySlice';
import HTMLReactParser from 'html-react-parser';
import LoaderScreen from '../utils/loaderScreen';
import { NotFound } from './Styles/notFound';

function DonatePage() {
  const dispatch = useDispatch();

  const { charityArticleDetails, charityLoading } = useSelector(
    (state) => state.charity
  );

  useEffect(() => {
    dispatch(FetchingCharity(10));

    return () => {
      dispatch(reset());
    };
  }, []);

  return (
    <>
      <div class="stricky-header stricked-menu main-menu">
        <div class="sticky-header__content"></div>
      </div>

      <section class="page-header">
        <div
          class="page-header-bg"
          style={{
            backgroundImage:
              'url(assets/images/backgrounds/page-header-bg.jpg)',
          }}
        ></div>
        <div class="container">
          <div class="page-header__inner">
            <ul class="thm-breadcrumb list-unstyled">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>
                <span>/</span>
              </li>
              <li class="active">Donations</li>
            </ul>
            <h2>Donations</h2>
          </div>
        </div>
      </section>

      {charityLoading ? (
        <LoaderScreen />
      ) : (
        <section class="donation">
          <div class="container">
            <div class="row">
              {charityArticleDetails?.length > 0 ? (
                charityArticleDetails?.map((item, key) => (
                  <div
                    key={key}
                    class="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
                    data-wow-delay="100ms"
                  >
                    <div class="causes-one__single">
                      <div class="causes-one__img">
                        <img
                          src={item?.cover_pic}
                          alt=""
                          style={{
                            objectFit: 'cover',
                            height: '300px',
                          }}
                        />
                        <div class="causes-one__cat">
                          <p>{item?.hash_tag}</p>
                        </div>
                      </div>
                      <div class="causes-one__content">
                        <h3
                          class="causes-one__title"
                          style={{
                            overflow: 'hidden',
                            whiteSpace: 'wrap',
                            maxHeight: '2rem',
                            lineClamp: 1,
                            textOverflow: 'ellipsis',
                          }}
                        >
                          <NavLink to={`/donate_details/${item?._id}`}>
                            {item?.title}
                          </NavLink>
                        </h3>
                        <p
                          class="causes-one__text"
                          style={{
                            overflow: 'hidden',
                            whiteSpace: 'wrap',
                            maxHeight: '5rem',
                            lineClamp: 2,
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {/* There are many of lorem, but majori have suffered
                      alteration in some form. */}
                          {HTMLReactParser(HTMLReactParser(item?.article))}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <NotFound>
                  <div className="not_found_wrapper">
                    <h3 class="team-one__name">
                      <a href="team-details.html" style={{ color: '#283734' }}>
                        No Donation found
                      </a>
                    </h3>
                  </div>
                </NotFound>
              )}
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default DonatePage;
