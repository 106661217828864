import LandingPage from './Screen/LandingPage';
import 'swiper/css';
import 'swiper/css/bundle';
import Nav from './Components/nav/nav';
import Footer from './Components/fotter/footer';
import AboutPage from './Screen/AboutPage';
import Contact_Us from './Screen/Contact_Us';
import Gallery from './Screen/Gallery';
import Team from './Screen/Team';
import BecomeAVolunteer from './Screen/BecomeAVolunteer';
import DonatePage from './Screen/DonatePage';
import Donate_Now from './Screen/Donate_Now';
import Blog from './Screen/Blog';
import NewDetails from './Screen/NewDetails';
import { Routes, Route } from 'react-router-dom';
import Volunteer from './Screen/Volunteer';
import SideBard from './Components/nav/sideBard';
import React, { useState } from 'react';
import Resource from './Screen/Resources';
import ProjectPage from './Screen/ProjectPage';
import Services from './Screen/Services';
import { ToastContainer } from 'react-toastify';
import DonateDetails from './Screen/Donate_Details';
import ProjectDetails from './Screen/ProjectDetails';
import ResourcesDetails from './Screen/resourecesDetails';
import ServicesDetails from './Screen/ServicesDetails';
import CreateTestimony from './Screen/createTestimony';
import 'react-loading-skeleton/dist/skeleton.css';
import Faq from './Screen/faq';
import 'react-toastify/dist/ReactToastify.css';

// import "owl.carousel";

export const NavContext = React.createContext();

function App() {
  const [openSideBar, setOpenSideBar] = useState(false);

  return (
    <NavContext.Provider value={{ setOpenSideBar: setOpenSideBar }}>
      <div class="custom-cursor">
        <div class="custom-cursor__cursor"></div>
        <div class="custom-cursor__cursor-two"></div>

        {/* <div class="preloader">
        <div class="preloader__image"></div>
      </div> */}

        <div class="page-wrapper">
          <Nav />

          {openSideBar && <SideBard />}

          <div class="stricky-header stricked-menu main-menu">
            <div class="sticky-header__content"></div>
          </div>

          <Routes>
            <Route path="/" element={<LandingPage />} />

            <Route path="/about" element={<AboutPage />} />

            <Route path="/contact" element={<Contact_Us />} />

            <Route path="/services" element={<Services />} />

            <Route path="/gallery" element={<Gallery />} />

            <Route path="/volunteer" element={<Volunteer />} />

            <Route path="/project" element={<ProjectPage />} />

            <Route path="/project/:project" element={<ProjectDetails />} />

            <Route path="/resourse" element={<Resource />} />

            <Route path="/resourse/:resourse" element={<ResourcesDetails />} />

            <Route path="/services/:services" element={<ServicesDetails />} />

            <Route path="/team" element={<Team />} />

            <Route path="/be_a_volunteer" element={<BecomeAVolunteer />} />

            <Route path="/create_testimony" element={<CreateTestimony />} />

            <Route path="/blog" element={<Blog />} />

            <Route path="/blogDetails/:blog" element={<NewDetails />} />

            <Route path="/donate" element={<DonatePage />} />

            <Route path="/donate_now/:title" element={<Donate_Now />} />

            <Route path="/donate_details/:donate" element={<DonateDetails />} />

            <Route path="faq" element={<Faq />} />
          </Routes>

          <Footer />
        </div>

        <ToastContainer
          position="top-right"
          autoClose={3500}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
        />

        <div class="mobile-nav__wrapper">
          <div class="mobile-nav__overlay mobile-nav__toggler"></div>

          <div class="mobile-nav__content">
            <span class="mobile-nav__close mobile-nav__toggler">
              <i class="fa fa-times"></i>
            </span>

            <div class="logo-box">
              <a href="index.html" aria-label="logo image">
                <img
                  src="assets/images/resources/logo-2.png"
                  width="143"
                  alt=""
                />
              </a>
            </div>

            <div class="mobile-nav__container"></div>

            <ul class="mobile-nav__contact list-unstyled">
              <li>
                <i class="fa fa-envelope"></i>
                <a href="mailto:needhelp@packageName__.com">
                  needhelp@oxpins.com
                </a>
              </li>
              <li>
                <i class="fa fa-phone-alt"></i>
                <a href="tel:666-888-0000">666 888 0000</a>
              </li>
            </ul>
            <div class="mobile-nav__top">
              <div class="mobile-nav__social">
                <a href="#" class="fab fa-twitter"></a>
                <a href="#" class="fab fa-facebook-square"></a>
                <a href="#" class="fab fa-pinterest-p"></a>
                <a href="#" class="fab fa-instagram"></a>
              </div>
            </div>
          </div>
        </div>

        <div class="search-popup">
          <div class="search-popup__overlay search-toggler"></div>

          <div class="search-popup__content">
            <form action="#">
              <label for="search" class="sr-only">
                search here
              </label>
              <input type="text" id="search" placeholder="Search Here..." />
              <button type="submit" aria-label="search submit" class="thm-btn">
                <i class="icon-magnifying-glass"></i>
              </button>
            </form>
          </div>
        </div>

        <a href="#" data-target="html" class="scroll-to-target scroll-to-top">
          <i class="icon-up-arrow"></i>
        </a>
      </div>
    </NavContext.Provider>
  );
}

export default App;
