import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetSingleBlog, PostComment } from "../features/blog/blogServicesSync";
import { useParams } from "react-router-dom";
import LoaderScreen from "../utils/loaderScreen";
import moment from "moment";
import HTMLReactParser from "html-react-parser";
import { ArticleWrapper } from "./Styles/aritcleWrapper.style";
import { BaseUrl } from "../utils/baseUrl";
import { Bars } from "react-loader-spinner";
import * as Yup from "yup";
import { useFormik } from "formik";
import Pic from "../Assets/avatar-profile-photo.png";

const baseSchema = Yup.object({
  username: Yup.string().required(),
  email: Yup.string().email().required(),
  comment: Yup.string().required(),
});

function NewDetails() {
  const dispatch = useDispatch();

  const { blog } = useParams();

  const { singleBlogDetails, allBlogComment, blogLoading, commentLoader } =
    useSelector((state) => state.blog);

  useEffect(() => {
    dispatch(GetSingleBlog(blog));
  }, [blog]);

  console.log(allBlogComment, "na me come to this point of my life");

  const handleSubmited = async (value, { resetForm }) => {
    await dispatch(PostComment({ ...value, id: singleBlogDetails?._id }));

    resetForm();
  };

  const { values, handleBlur, handleChange, handleSubmit, errors } = useFormik({
    initialValues: {
      username: "",
      email: "",
      comment: "",
    },
    validationSchema: baseSchema,
    onSubmit: handleSubmited,
  });

  return (
    <ArticleWrapper>
      <section class="page-header">
        <div
          class="page-header-bg"
          style={{
            backgroundImage:
              "url(/assets/images/backgrounds/page-header-bg.jpg)",
          }}
        ></div>
        <div class="container">
          <div class="page-header__inner">
            <ul class="thm-breadcrumb list-unstyled">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>
                <span>/</span>
              </li>
              <li class="active">News details</li>
            </ul>
            <h2>News details</h2>
          </div>
        </div>
      </section>

      {blogLoading ? (
        <LoaderScreen />
      ) : (
        <section class="news-details">
          <div class="container">
            <div class="row">
              <div class="col-xl-8 col-lg-7">
                <div class="news-details__left">
                  <div class="news-details__img">
                    <img
                      src={`${singleBlogDetails?.cover_pic}`}
                      alt=""
                      style={{
                        maxHeight: "50rem",
                        minHeight: "30rem",
                        maxWidth: "100%",
                        objectPosition: "center",
                        objectFit: "cover",
                        borderRadius: "8px",
                      }}
                    />
                    <div class="news-details__date">
                      <p>{`${moment(singleBlogDetails?.date).format(
                        "DD MMM, YYYY"
                      )}`}</p>
                    </div>
                  </div>
                  <div class="news-details__content">
                    <ul class="list-unstyled news-details__meta">
                      <li>
                        <a href="news-details.html">
                          <i class="far fa-user-circle"></i>{" "}
                          {singleBlogDetails?.username}
                        </a>
                      </li>
                      <li>
                        <a href="news-details.html">
                          <i class="fas fa-comments"></i>{" "}
                          {`${allBlogComment?.count} Comments`}
                        </a>
                      </li>
                    </ul>
                    <h3 class="news-details__title">
                      {singleBlogDetails?.title}
                    </h3>
                    <p class="news-details__text-1 content">
                      {HTMLReactParser(
                        HTMLReactParser(`${singleBlogDetails?.article}`)
                      )}
                    </p>
                  </div>
                  <div class="news-details__bottom">
                    <p class="news-details__tags">
                      <span>Tags</span>

                      {singleBlogDetails?.hash_tag
                        ?.split(" ")
                        ?.map((item, key) => (
                          <a href="#">{item}</a>
                        ))}
                    </p>
                    <div class="news-details__social-list">
                      <a
                        href={`https://twitter.com/share?url=${BaseUrl}/blogDetails/${singleBlogDetails?._id}&text=${singleBlogDetails?.title}`}
                        target={"blank"}
                      >
                        <i class="fab fa-twitter"></i>
                      </a>
                      <a
                        href={`https://www.facebook.com/sharer.php?u=${BaseUrl}/blogDetails/${singleBlogDetails?._id}`}
                        target={"blank"}
                      >
                        <i class="fab fa-facebook"></i>
                      </a>
                      <a
                        href={`https://www.pinterest.com/pin/create/button/?url=${BaseUrl}/blogDetails/${singleBlogDetails?._id}&media=${singleBlogDetails?.cover_pic}&description=${singleBlogDetails?.title}`}
                        target="_blank"
                      >
                        <i class="fab fa-pinterest-p"></i>
                      </a>
                      <a
                        href={`https://www.instagram.com/?url=${BaseUrl}/blogDetails/${singleBlogDetails?._id}`}
                        target="_blank"
                      >
                        <i class="fab fa-instagram"></i>
                      </a>
                    </div>
                  </div>
                  {/* <div class="news-details__pagenation-box">
                    <ul class="list-unstyled news-details__pagenation">
                      <li>Why is it important to support them</li>
                      <li>Survived not only five centuries</li>
                    </ul>
                  </div> */}

                  <div class="comment-one">
                    <h3 class="comment-one__title">
                      {`${allBlogComment?.count} Comments`}
                    </h3>
                    <div className="articleWrapper__commentTextSectionWrapper">
                      {allBlogComment?.data?.map((item, key) => (
                        <div class="comment-one__single" key={key}>
                          <div class="comment-one__image">
                            <img src={Pic} alt="" />
                          </div>
                          <div
                            class="comment-one__content"
                            style={{
                              width: "100%",
                            }}
                          >
                            <h3>{item?.username}</h3>
                            <p>{item?.comment}</p>
                            <a class="thm-btn comment-one__btn">{`${moment(
                              item?.date
                            ).format("DD MMM, YYYY")}`}</a>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div class="comment-form">
                    <h3 class="comment-form__title">Leave a comment</h3>
                    <form
                      class="comment-one__form contact-form-validated"
                      novalidate="novalidate"
                      onSubmit={handleSubmit}
                    >
                      <div class="row">
                        <div class="col-xl-6">
                          <div class="comment-form__input-box">
                            <input
                              type="text"
                              placeholder="Your name"
                              name="username"
                              value={values?.username || ""}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              style={{
                                border: errors.username
                                  ? `1px solid red`
                                  : `none`,
                              }}
                            />
                          </div>
                        </div>
                        <div class="col-xl-6">
                          <div class="comment-form__input-box">
                            <input
                              type="email"
                              placeholder="Email address"
                              name="email"
                              value={values?.email || ""}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              style={{
                                border: errors.email ? `1px solid red` : `none`,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-xl-12">
                          <div class="comment-form__input-box text-message-box">
                            <textarea
                              name="comment"
                              value={values?.comment || ""}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder="Write a message"
                              style={{
                                border: errors.comment
                                  ? `1px solid red`
                                  : `none`,
                              }}
                            ></textarea>
                          </div>
                          <div class="comment-form__btn-box">
                            {commentLoader ? (
                              <Bars
                                height="80"
                                width="80"
                                color="#283734"
                                ariaLabel="bars-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                              />
                            ) : (
                              <button
                                type="submit"
                                class="thm-btn comment-form__btn"
                              >
                                Submit comment
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-5">
                <div class="sidebar">
                  <div class="sidebar__single sidebar__search">
                    <form action="#" class="sidebar__search-form">
                      <input type="search" placeholder="Search here" />
                      <button type="submit">
                        <i class="icon-magnifying-glass"></i>
                      </button>
                    </form>
                  </div>

                  <div class="sidebar__single sidebar__tags">
                    <div
                      class="sidebar-shape-1"
                      style={{
                        backgroundImage:
                          "url(assets/images/shapes/sidebar-shape-1.png)",
                      }}
                    ></div>
                    <h3 class="sidebar__title">Tags</h3>
                    <div class="sidebar__tags-list">
                      {singleBlogDetails?.hash_tag
                        ?.split(" ")
                        ?.map((item, key) => (
                          <a>{item}</a>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </ArticleWrapper>
  );
}

export default NewDetails;
