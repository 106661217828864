import { createSlice } from '@reduxjs/toolkit';
import {
  GetVolunteers,
  RequestVolunteer,
  uploadImageVolunteers,
} from './volunteersServicesSync';

const initialState = {
  isError: false,
  isSuccess: false,
  message: '',
  volunteerLoading: false,
  volunteerArticleDetails: {},
  volunteerlimit: 9,
  volunteerPage: 1,
  isPicLoading: false,
  contentDetails: {},
};

export const volunteerSlices = createSlice({
  name: 'volunteer',
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
      state.volunteerLoading = false;
      state.volunteerArticleDetails = {};
      state.volunteerlimit = 9;
      state.volunteerPage = 1;
      state.isPicLoading = false;
      state.contentDetails = {};
    },

    updateVolunteerPage: (state, action) => {
      state.volunteerPage = action.payload;
    },

    updateCoverPic: (state, action) => {
      state.contentDetails = action.payload;
    },
  },
  extraReducers: {
    [uploadImageVolunteers.pending]: (state, action) => {
      state.isPicLoading = true;
    },

    [uploadImageVolunteers.fulfilled]: (state, action) => {
      state.isPicLoading = false;
      state.message = action.payload?.message;
      state.contentDetails = action.payload?.data;
    },

    [uploadImageVolunteers.rejected]: (state, action) => {
      state.isPicLoading = false;
      state.message = action.payload?.message;
    },
    [GetVolunteers.pending]: (state, action) => {
      if (state.volunteerArticleDetails?.docs?.length <= 0) {
        state.volunteerLoading = true;
      }
    },
    [GetVolunteers.fulfilled]: (state, action) => {
      state.volunteerLoading = false;
      state.message = action.payload?.message;
      state.volunteerArticleDetails = action?.payload?.data;
    },
    [GetVolunteers.rejected]: (state, action) => {
      state.volunteerLoading = false;
      state.message = action.payload?.message;
    },

    [RequestVolunteer.pending]: (state, action) => {
      state.volunteerLoading = true;
    },

    [RequestVolunteer.fulfilled]: (state, action) => {
      state.volunteerLoading = false;
      state.message = action.payload?.message;
    },

    [RequestVolunteer.rejected]: (state, action) => {
      state.volunteerLoading = false;
      state.message = action.payload?.message;
    },
  },
});

export const { reset, updateVolunteerPage, updateCoverPic } =
  volunteerSlices.actions;

export default volunteerSlices.reducer;
