import APIs from "../apiUrl";

const FetchingCharitys = async (payload) => {
  try {
    const response = await APIs.get(`/get_charity/${payload}`);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getSingleCharity = async (payload) => {
  try {
    const response = await APIs.get(`/get_single_charity/${payload}`);

    console.log(response, "solved");

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const CharityServices = {
  FetchingCharitys,
  getSingleCharity,
};

export default CharityServices;
