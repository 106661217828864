import { createSlice } from '@reduxjs/toolkit';
import { FetchingCharity, GetSingleCharity } from './charityServiceSync';

const initialState = {
  isError: false,
  isSuccess: false,
  message: '',
  charityLoading: false,
  singleCharityDetails: {},
  charityArticleDetails: [],
};

export const charitySlice = createSlice({
  name: 'donation',
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
      state.charityLoading = false;
      state.singleCharityDetails = {};
      state.charityArticleDetails = [];
    },
  },
  extraReducers: {
    [FetchingCharity.pending]: (state, action) => {
      if (state?.charityArticleDetails?.length <= 0) {
        state.charityLoading = true;
      }
    },

    [FetchingCharity.fulfilled]: (state, action) => {
      state.charityLoading = false;
      state.message = action.payload?.message;
      state.charityArticleDetails = action?.payload?.data;
    },

    [FetchingCharity.rejected]: (state, action) => {
      state.charityLoading = false;
      state.message = action.payload?.message;
    },

    [GetSingleCharity.pending]: (state, action) => {
      state.charityLoading = true;
    },

    [GetSingleCharity.fulfilled]: (state, action) => {
      state.charityLoading = false;
      state.message = action.payload?.message;
      state.singleCharityDetails = action.payload?.data;
    },

    [GetSingleCharity.rejected]: (state, action) => {
      state.charityLoading = false;
      state.message = action.payload?.message;
    },
  },
});

export const { reset } = charitySlice.actions;

export default charitySlice.reducer;
