import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { GetServices } from "../features/services/servicesServicesSync";
import { reset, updateServicesPage } from "../features/services/servicesSlices";
import HTMLReactParser from "html-react-parser";
import { DomPaginationState } from "../Components/DOM_Pagination_State";
import LoaderScreen from "../utils/loaderScreen";
import { NotFound } from "./Styles/notFound";

function Services() {
  const dispatch = useDispatch();

  const {
    servicesArticleDetails,
    serviceLoading,
    serviceslimit,
    servicesPage,
  } = useSelector((state) => state.services);

  useEffect(() => {
    dispatch(GetServices({ page: servicesPage, limit: serviceslimit }));

    return () => {
      dispatch(reset());
    };
  }, []);

  const nextPage = () => {
    if (!serviceLoading) {
      if (servicesArticleDetails?.hasNextPage) {
        dispatch(updateServicesPage(servicesPage + 1));

        dispatch(
          GetServices({
            page: servicesPage + 1,
            limit: serviceslimit,
          })
        );
      }
    }
  };

  const prevPage = () => {
    if (!serviceLoading) {
      if (servicesArticleDetails?.hasPrevPage) {
        dispatch(updateServicesPage(servicesPage - 1));

        dispatch(
          GetServices({
            page: servicesPage - 1,
            limit: serviceslimit,
          })
        );
      }
    }
  };

  return (
    <>
      <section class="page-header">
        <div
          class="page-header-bg"
          style={{
            backgroundImage:
              "url(assets/images/backgrounds/page-header-bg.jpg)",
          }}
        ></div>
        <div class="container">
          <div class="page-header__inner">
            <ul class="thm-breadcrumb list-unstyled">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>
                <span>/</span>
              </li>
              <li class="active">Services</li>
            </ul>
            <h2>Services</h2>
          </div>
        </div>
      </section>
      <section class="events-page">
        <div class="container">
          <div class="row">
            {serviceLoading ? (
              <LoaderScreen />
            ) : servicesArticleDetails?.docs?.length > 0 ? (
              servicesArticleDetails?.docs?.map((item, key) => (
                <div class="col-xl-4 col-lg-6 col-md-6" key={key}>
                  <div class="events-one__single">
                    <div class="events-one__img">
                      <img
                        src={item?.cover_pic}
                        alt=""
                        style={{ objectFit: "cover", height: "500px" }}
                      />
                      <div class="events-one__date">
                        <p>{item?.title}</p>
                      </div>
                      <div class="events-one__content">
                        {/* <ul class="list-unstyled events-one__meta">
                      <li>
                        <i class="fas fa-clock"></i>8:00pm
                      </li>
                      <li>
                        <i class="fas fa-map-marker-alt"></i>CLINIC
                      </li>
                    </ul> */}
                        <h3
                          class="events-one__title"
                          style={{
                            overflow: "hidden",
                            whiteSpace: "wrap",
                            maxHeight: "4rem",
                            lineClamp: 2,
                            textOverflow: "ellipsis",
                          }}
                        >
                          <NavLink to={`/services/${item?.title}`}>
                            {HTMLReactParser(HTMLReactParser(item?.article))}
                          </NavLink>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <NotFound>
                <div className="not_found_wrapper">
                  <h3 class="team-one__name">
                    <a href="team-details.html" style={{ color: "#283734" }}>
                      No Service found
                    </a>
                  </h3>
                </div>
              </NotFound>
            )}

            {servicesArticleDetails?.docs?.length > 0 && (
              <DomPaginationState
                onNext={nextPage}
                onPrev={prevPage}
                skip={servicesArticleDetails?.page}
                loading={serviceLoading}
                hasNext={servicesArticleDetails?.hasNextPage}
                hasPrev={servicesArticleDetails?.hasPrevPage}
              />
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default Services;
