import APIs from "../apiUrl";

const InitializeDonate = async (payload) => {
  try {
    const response = await APIs.post("/pay", payload);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const DonationServices = {
  InitializeDonate,
};

export default DonationServices;
