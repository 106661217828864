import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import SponsorServices from "./sponsorServices";

export const GetSponsors = createAsyncThunk(
  "sponsor/getSponsors",
  async (payload) => {
    try {
      let response = await SponsorServices.getSponsor(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);
