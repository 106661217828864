import APIs from "../apiUrl";

const getGallery = async (payload) => {
  try {
    const response = await APIs.get(
      `/all_gallery/${payload.page}/${payload.limit}`
    );

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const GalleryServices = {
  getGallery,
};

export default GalleryServices;
