import { createAsyncThunk } from "@reduxjs/toolkit";
import volunteersServices from "./volunteersServices";
import { toast } from "react-toastify";

export const uploadImageVolunteers = createAsyncThunk(
  "volunteer/uploadimage",
  async (payload) => {
    try {
      let response = await volunteersServices.VolunteersImageUpload(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const RequestVolunteer = createAsyncThunk(
  "volunteer/requestVolunteer",
  async (payload) => {
    try {
      let response = await volunteersServices.RequestVolunteer(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetVolunteers = createAsyncThunk(
  "volunteer/getVolunteers",
  async (payload) => {
    try {
      let response = await volunteersServices.getVolunteers(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);
