import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import FaqServices from './faqServices';

export const GetFaq = createAsyncThunk('faq/getfaq', async (payload) => {
  try {
    let response = await FaqServices.getFaq(payload);

    return response;
  } catch (error) {
    toast(error.message);
    throw error;
  }
});
