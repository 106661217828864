import APIs from "../apiUrl";

const getServices = async (payload) => {
  try {
    const response = await APIs.get(
      `/get_enduser_services/${payload.page}/${payload.limit}`
    );

    console.log(response?.data, "fesical work bro");

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getSingleServices = async (payload) => {
  try {
    const response = await APIs.get(`/get_single_services_by_title/${payload}`);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const ServicesServices = {
  getServices,
  getSingleServices,
};

export default ServicesServices;
