import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, EffectFade, Autoplay } from 'swiper/modules';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GetServices } from '../features/services/servicesServicesSync';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { GetProject } from '../features/project/projectServicesSync';
import moment from 'moment';
import { GetTestimony } from '../features/testimony/testimonyServicesSync';
import PicStatus from '../Assets/avatar-profile-photo.png';
import { GetTeam } from '../features/team/teamServicesSync';
import { GetBlog } from '../features/blog/blogServicesSync';
import { GetGallery } from '../features/gallery/galleryServicesSync';
import { GetSponsors } from '../features/sponsor/sponsorServicesSync';
import { AdvNotFound } from './Styles/notFound';

function LandingPage() {
  const dispatch = useDispatch();

  const {
    servicesArticleDetails,
    serviceLoading,
    serviceslimit,
    servicesPage,
  } = useSelector((state) => state.services);

  const { allGallery, contentLoader, gallerylimit, galleryPage } = useSelector(
    (state) => state.gallery
  );

  const { projectArticleDetails, projectLoading, projectlimit, projectPage } =
    useSelector((state) => state.project);

  const {
    testimonyArticleDetails,
    testimonyLoading,
    testimonylimit,
    testimonyPage,
  } = useSelector((state) => state.testimony);

  const { teamArticleDetails, teamLoading, teamlimit, teamPage } = useSelector(
    (state) => state.team
  );

  const { blogArticleDetails, blogLoading, bloglimit, blogPage } = useSelector(
    (state) => state.blog
  );

  const { allSponsor, sponsorLoader } = useSelector((state) => state.sponsor);

  useEffect(() => {
    console.log('joshua ejike');
    Promise.all([
      dispatch(GetServices({ page: 1, limit: 10 })),
      dispatch(GetProject({ page: 1, limit: 10 })),
      dispatch(GetTeam({ page: 1, limit: 20 })),
      dispatch(GetTestimony({ page: 1, limit: 12 })),
      dispatch(GetBlog({ page: 1, limit: 10 })),
      dispatch(GetGallery({ page: 1, limit: 12 })),
      dispatch(GetSponsors()),
    ]);
  }, []);

  console.log(
    testimonyArticleDetails,
    allGallery,
    allSponsor,
    'testimonyArticleDetails'
  );

  return (
    <>
      <div class="stricky-header stricked-menu main-menu main-menu-two">
        <div class="sticky-header__content"></div>
      </div>

      <section class="main-slider-two clearfix">
        <div
          class="swiper-container thm-swiper__slider"
          // data-swiper-options='{"slidesPerView": 1, "loop": true,
          //     "effect": "fade",
          //     "pagination": {
          //     "el": "#main-slider-pagination",
          //     "type": "bullets",
          //     "clickable": true
          //     },
          //     "navigation": {
          //     "nextEl": "#main-slider__swiper-button-next",
          //     "prevEl": "#main-slider__swiper-button-prev"
          //     },
          //     "autoplay": {
          //     "delay": 5000
          //     }}'
        >
          <Swiper
            pagination={{
              type: 'bullets',
              el: '#main-slider-pagination',
              clickable: true,
            }}
            effect={'fade'}
            navigation={{
              nextEl: '#main-slider__swiper-button-next',
              prevEl: '#main-slider__swiper-button-prev',
            }}
            //   speed={1500}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            loop={true}
            slidesPerView={1}
            //   centeredSlides={true}
            modules={[Pagination, Navigation, EffectFade, Autoplay]}
            class="swiper-wrapper"
          >
            {/* <SwiperSlide> */}
            <SwiperSlide class="swiper-slide">
              <div
                class="image-layer-two"
                style={{
                  backgroundImage:
                    'url(assets/images/backgrounds/main-slider-2-1.jpg)',
                }}
              ></div>

              <div class="main-slider-two-shape-1">
                <img
                  src="assets/images/shapes/main-slider-two-shape-1.png"
                  alt=""
                />
              </div>
              <div class="main-slider-two-shape-2">
                <img
                  src="assets/images/shapes/main-slider-two-shape-2.png"
                  alt=""
                />
              </div>

              <div class="container">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="main-slider-two__content">
                      <p class="main-slider-two__sub-title">
                        Helping Today. Helping Tommorow
                      </p>
                      <h2
                        class="main-slider-two__title"
                        // style={{ fontSize: "40px", lineHeight: "30px" }}
                      >
                        Empower the Community with
                        <br /> comprehensive support
                      </h2>
                      <div class="main-slider-two__btn-box">
                        <NavLink to="/about">
                          <a class="thm-btn main-slider-two__btn">
                            Discover more
                          </a>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            {/* </SwiperSlide> */}

            {/* <SwiperSlide> */}
            <SwiperSlide class="swiper-slide">
              <div
                class="image-layer-two"
                style={{
                  backgroundImage:
                    'url(assets/images/backgrounds/main-slider-2-2.jpg)',
                }}
              ></div>

              <div class="main-slider-two-shape-1">
                <img
                  src="assets/images/shapes/main-slider-two-shape-1.png"
                  alt=""
                />
              </div>
              <div class="main-slider-two-shape-2">
                <img
                  src="assets/images/shapes/main-slider-two-shape-2.png"
                  alt=""
                />
              </div>

              <div class="container">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="main-slider-two__content">
                      <p class="main-slider-two__sub-title">
                        Helping Today. Helping Tommorow
                      </p>
                      <h2 class="main-slider-two__title">
                        encompassing mental health, <br /> HIV care and
                        prevention
                      </h2>
                      <div class="main-slider-two__btn-box">
                        <NavLink to="/about">
                          <a class="thm-btn main-slider-two__btn">
                            Discover more
                          </a>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            {/* </SwiperSlide> */}

            {/* <SwiperSlide> */}
            <SwiperSlide class="swiper-slide">
              <div
                class="image-layer-two"
                style={{
                  backgroundImage:
                    'url(assets/images/backgrounds/main-slider-2-3.jpg)',
                }}
              ></div>

              <div class="main-slider-two-shape-1">
                <img
                  src="assets/images/shapes/main-slider-two-shape-1.png"
                  alt=""
                />
              </div>
              <div class="main-slider-two-shape-2">
                <img
                  src="assets/images/shapes/main-slider-two-shape-2.png"
                  alt=""
                />
              </div>

              <div class="container">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="main-slider-two__content">
                      <p class="main-slider-two__sub-title">
                        Helping Today. Helping Tommorow
                      </p>
                      <h2 class="main-slider-two__title">
                        capacity-building, and <br /> economic empowerment.
                      </h2>
                      <div class="main-slider-two__btn-box">
                        <NavLink to="/about">
                          <a class="thm-btn main-slider-two__btn">
                            Discover more
                          </a>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            {/* </SwiperSlide> */}
          </Swiper>

          <div class="main-slider-two__nav">
            <div
              class="swiper-button-prev"
              id="main-slider__swiper-button-next"
            >
              <i class="icon-left-arrow"></i>
            </div>
            <div
              class="swiper-button-next"
              id="main-slider__swiper-button-prev"
            >
              <i class="icon-right-arrow"></i>
            </div>
          </div>
        </div>
      </section>

      <section class="feature-two">
        <div class="container">
          <div class="thm-swiper__slider swiper-container">
            {serviceLoading ? (
              <SkeletonTheme baseColor="#2A292933" highlightColor="#444">
                <div>
                  <Skeleton width="100vw" height="15rem" />
                </div>
              </SkeletonTheme>
            ) : servicesArticleDetails?.docs?.length <= 0 ? (
              <AdvNotFound>
                <div className="not_found_wrapper">
                  <h3 class="team-one__name">
                    <a style={{ color: '#283734' }}>No Service found</a>
                  </h3>
                </div>
              </AdvNotFound>
            ) : (
              <Swiper
                spaceBetween={100}
                slidesPerView={3}
                speed={1500}
                autoplay={{
                  delay: 5000,
                }}
                loop={true}
                breakpoints={{
                  0: {
                    spaceBetween: 30,
                    slidesPerView: 1,
                  },
                  375: {
                    spaceBetween: 30,
                    slidesPerView: 1,
                  },
                  575: {
                    spaceBetween: 30,
                    slidesPerView: 2,
                  },
                  767: {
                    spaceBetween: 50,
                    slidesPerView: 2,
                  },
                  991: {
                    spaceBetween: 50,
                    slidesPerView: 3,
                  },
                  1199: {
                    spaceBetween: 100,
                    slidesPerView: 3,
                  },
                }}
                //   centeredSlides={true}
                modules={[Pagination, Navigation, Autoplay]}
                class="swiper-wrapper"
              >
                {servicesArticleDetails?.docs?.map((item, key) => (
                  <SwiperSlide key={key} class="swiper-slide">
                    <div class="wow fadeInUp" data-wow-delay="100ms">
                      <div class="feature-two__single">
                        <div class="feature-two__single-inner">
                          <div
                            class="feature-two-shape-1"
                            style={{
                              backgroundImage:
                                'url(assets/images/shapes/feature-two-shape-1.png)',
                            }}
                          ></div>
                          <div class="feature-two__content-box">
                            <div class="feature-two__content-left">
                              <p class="feature-two__tagline">Service</p>
                              <h3 class="feature-two__title">
                                <a href="about.html">{item?.title}</a>
                              </h3>
                            </div>
                            <div class="feature-two__icon"></div>
                          </div>
                        </div>
                        <div class="feature-two__arrow">
                          <NavLink to={`/services/${item?.title}`}>
                            <span class="icon-right-arrow"></span>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        </div>
      </section>

      <section class="about-two">
        <div class="container">
          <div class="row">
            <div class="col-xl-6">
              <div class="about-two__left">
                <div
                  class="about-two__img-box  wow slideInLeft"
                  data-wow-delay="100ms"
                  data-wow-duration="2500ms"
                >
                  <div class="about-two__img">
                    <img
                      src="assets/images/resources/about-two-img-1.jpg"
                      alt=""
                    />
                  </div>
                  <div class="about-two__img-two">
                    <img
                      src="assets/images/resources/about-two-img-2.jpg"
                      alt=""
                    />
                  </div>
                  <div class="about-two__left-title-box">
                    <h3 class="about-two__left-title">Help us today!</h3>
                  </div>
                  <div class="about-two__shape-1 zoominout">
                    <img
                      src="assets/images/shapes/about-two-shape-1.png"
                      alt=""
                    />
                  </div>
                  <div class="about-two__shape-2 rotate-me">
                    <img
                      src="assets/images/shapes/about-two-shape-2.png"
                      alt=""
                    />
                  </div>
                  <div class="about-two__shape-3 float-bob-y">
                    <img
                      src="assets/images/shapes/about-two-shape-3.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="about-two__right">
                <div class="section-title text-left">
                  <span class="section-title__tagline">
                    Get to know about oxpins
                  </span>
                  <h2 class="section-title__title">ICTHARAE</h2>
                </div>
                <p class="about-two__text-1">
                  ICTHARAE’s goal is to provide adequate health care and human
                  rights services for marginalized persons in Nigeria, as a
                  community focused organization.
                </p>
                <ul class="list-unstyled about-two__points">
                  <li>
                    <div class="icon">
                      <span class="icon-target"></span>
                    </div>
                    <div class="text">
                      <h4>Our mission</h4>
                      <p>
                        Empower marginalized individuals with inclusive
                        services, regardless of religion, sexual orientation or
                        culture, through education and training.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div class="icon">
                      <span class="icon-solidarity-1"></span>
                    </div>
                    <div class="text">
                      <h4>Our Vision</h4>
                      <p>
                        To see a society where all persons no matter their
                        sexual orientation and gender identity have access to
                        adequate legal and quality health care services.
                      </p>
                    </div>
                  </li>
                </ul>

                <NavLink to="/about">
                  <a class="thm-btn about-two__btn">Discover More</a>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="causes-two">
        <div
          class="causes-two-shape-1"
          style={{
            backgroundImage: 'url(assets/images/shapes/causes-two-shape-1.png)',
          }}
        ></div>
        <div class="container">
          <div class="causes-two__top">
            <div class="row">
              <div class="col-xl-7 col-lg-6">
                <div class="causes-two-top__left">
                  <div class="section-title text-left">
                    <span class="section-title__tagline">
                      Project HighLight
                    </span>
                    <h2 class="section-title__title">
                      Support a Worthy Cause: Contribute to a Better Future
                    </h2>
                  </div>
                </div>
              </div>
              <div class="col-xl-5 col-lg-6">
                <div class="causes-two-top__right">
                  <p class="causes-two-top__text">
                    Join us in making a positive impact by donating to popular
                    causes. Your support will fuel projects that address
                    pressing issues and create lasting change. Together, we can
                    make a difference.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="thm-swiper__slider swiper-container">
              {projectLoading ? (
                <SkeletonTheme baseColor="#2A292933" highlightColor="#444">
                  <div>
                    <Skeleton width="100vw" height="30rem" />
                  </div>
                </SkeletonTheme>
              ) : projectArticleDetails?.docs?.length <= 0 ? (
                <AdvNotFound>
                  <div className="not_found_wrapper">
                    <h3 class="team-one__name">
                      <a style={{ color: '#283734' }}>No Event found</a>
                    </h3>
                  </div>
                </AdvNotFound>
              ) : (
                <Swiper
                  // items={3}
                  slidesPerView={3}
                  speed={1500}
                  autoplay={{
                    delay: 5000,
                  }}
                  spaceBetween={30}
                  loop={true}
                  breakpoints={{
                    0: {
                      spaceBetween: 30,
                      slidesPerView: 1,
                    },
                    375: {
                      spaceBetween: 30,
                      slidesPerView: 1,
                    },
                    575: {
                      spaceBetween: 30,
                      slidesPerView: 2,
                    },
                    768: {
                      spaceBetween: 50,
                      slidesPerView: 2,
                    },
                    992: {
                      spaceBetween: 50,
                      slidesPerView: 3,
                    },
                  }}
                  modules={[Pagination, Navigation, Autoplay]}
                  class="swiper-wrapper"
                >
                  {projectArticleDetails?.docs?.map((item, key) => (
                    <SwiperSlide class="swiper-slide" key={key}>
                      <div class="events-one__single">
                        <div class="events-one__img">
                          <img
                            src={item?.cover_pic}
                            alt=""
                            style={{ objectFit: 'cover', height: '500px' }}
                          />
                          <div class="events-one__date">
                            <p>{`${moment(item?.date).format(
                              'DD MMM, YYYY'
                            )}`}</p>
                          </div>
                          <div class="events-one__content">
                            <ul class="list-unstyled events-one__meta">
                              <li>
                                <i class="fas fa-clock"></i>
                                {`${moment(item?.date).format('h:mma')}`}
                              </li>
                              <li>
                                <i class="fas fa-map-marker-alt"></i>
                                {item?.location?.slice(0, 14)}
                              </li>
                            </ul>
                            <h3
                              class="events-one__title"
                              style={{
                                overflow: 'hidden',
                                whiteSpace: 'wrap',
                                maxHeight: '2rem',
                                lineClamp: 1,
                                textOverflow: 'ellipsis',
                              }}
                            >
                              <NavLink to={`/project/${item?._id}`}>
                                {item?.title}
                              </NavLink>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}
            </div>
          </div>
        </div>
      </section>

      <section class="testimonial-two">
        <div class="container">
          <div class="section-title text-center">
            <span class="section-title__tagline">Our testimonials</span>
            <h2 class="section-title__title">
              What they’re talking <br /> about ICTHARAE
            </h2>
          </div>
          <div class="thm-swiper__slider swiper-container">
            {testimonyLoading ? (
              <SkeletonTheme baseColor="#2A292933" highlightColor="#444">
                <div>
                  <Skeleton width="100vw" height="30rem" />
                </div>
              </SkeletonTheme>
            ) : testimonyArticleDetails?.docs?.length <= 0 ? (
              <AdvNotFound>
                <div className="not_found_wrapper">
                  <h3 class="team-one__name">
                    <a style={{ color: '#283734' }}>No Testimony found</a>
                  </h3>
                </div>
              </AdvNotFound>
            ) : (
              <Swiper
                loop={true}
                autoplay={{
                  delay: 5000,
                }}
                spaceBetween={30}
                speed={500}
                breakpoints={{
                  0: {
                    spaceBetween: 30,
                    slidesPerView: 1,
                  },
                  375: {
                    spaceBetween: 30,
                    slidesPerView: 1,
                  },
                  575: {
                    spaceBetween: 30,
                    slidesPerView: 2,
                  },
                  768: {
                    spaceBetween: 30,
                    slidesPerView: 2,
                  },
                  992: {
                    spaceBetween: 30,
                    slidesPerView: 2,
                  },
                  1200: { spaceBetween: 30, slidesPerView: 3 },
                }}
                modules={[Pagination, Navigation, Autoplay]}
                class="swiper-wrapper"
              >
                {testimonyArticleDetails?.docs?.map((item, key) => (
                  <SwiperSlide class="swiper-slide" key={key}>
                    <div class="testimonial-two__single">
                      <div class="testimonial-two__img">
                        <img
                          src={
                            item?.cover_pic ? `${item?.cover_pic}` : PicStatus
                          }
                          alt=""
                        />
                      </div>
                      <div class="testimonial-two__content">
                        <div
                          class="testimonial-two__shape-1"
                          style={{
                            backgroundImage:
                              'url(assets/images/shapes/testimonial-two-shape-1.png)',
                          }}
                        ></div>
                        <p class="testimonial-two__text">{item?.about}</p>
                        <div class="testimonial-two__client-info">
                          <h4 class="testimonial-two__client-name">{`${item?.firstname} ${item?.lastname}`}</h4>
                          <p class="testimonial-two__client-sub-title">
                            {item?.user_type}
                          </p>
                        </div>
                        <div class="testimonial-two__rating">
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        </div>
      </section>

      <section class="gallery-two">
        <div class="gallery-one__bottom">
          <div class="gallery-one__container">
            <ul class="list-unstyled gallery-one__list">
              {contentLoader ? (
                <SkeletonTheme baseColor="#2A292933" highlightColor="#444">
                  <div>
                    <Skeleton width="100vw" height="15rem" />
                  </div>
                </SkeletonTheme>
              ) : allGallery?.docs?.lenth <= 0 ? (
                <AdvNotFound>
                  <div className="not_found_wrapper">
                    <h3 class="team-one__name">
                      <a style={{ color: '#283734' }}>No Gallery found</a>
                    </h3>
                  </div>
                </AdvNotFound>
              ) : (
                allGallery?.docs?.slice(0, 6)?.map((item, key) => (
                  <li
                    key={key}
                    class="gallery-one__single wow fadeInUp"
                    data-wow-delay="100ms"
                  >
                    <div class="gallery-one__img">
                      <img src={item?.cover_pic} alt="" />
                      <a
                        href="assets/images/gallery/gallery-1-1.jpg"
                        class="img-popup"
                      ></a>
                      <div class="gallery-one__content">
                        {/* <p class="gallery-one__sub-title">Charity</p> */}
                        <h5 class="gallery-one__title">
                          <a href="gallery.html">{item?.title}</a>
                        </h5>
                      </div>
                    </div>
                  </li>
                ))
              )}
            </ul>
          </div>
        </div>
      </section>

      <section class="help">
        <div
          class="help-bg jarallax"
          data-jarallax
          data-speed="0.2"
          data-imgPosition="50% 0%"
          style={{
            backgroundImage: 'url(assets/images/backgrounds/help-bg.jpg)',
          }}
        ></div>
        <div
          class="help-shape-1"
          style={{
            backgroundImage: 'url(assets/images/shapes/help-shape-1.png)',
          }}
        ></div>
        <div class="container">
          <div class="row" style={{ justifyContent: 'center' }}>
            <div class="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="100ms">
              <div class="help__single">
                <div class="help__single-inner">
                  <div class="help__icon">
                    <span class="icon-charity"></span>
                  </div>
                  <div class="help__content">
                    <h4 class="help__title">
                      <NavLink to="/be_a_volunteer">Become a volunteer</NavLink>
                    </h4>
                    <p class="help__text">
                      There are many variations of but the majority have simply
                      free text suffered.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="300ms">
              <div class="help__single">
                <div class="help__single-inner">
                  <div class="help__icon">
                    <span class="icon-fundraiser"></span>
                  </div>
                  <div class="help__content">
                    <h4 class="help__title">
                      <NavLink to="/donate">Start donating</NavLink>
                    </h4>
                    <p class="help__text">
                      There are many variations of but the majority have simply
                      free text suffered.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="team-one">
        <div class="container">
          <div class="section-title text-center">
            <span class="section-title__tagline">Our expert team</span>
            <h2 class="section-title__title">
              Meet the team behind <br /> their success story
            </h2>
          </div>
          <div class="row">
            {teamLoading ? (
              <SkeletonTheme baseColor="#2A292933" highlightColor="#444">
                <div>
                  <Skeleton width="100vw" height="30rem" />
                </div>
              </SkeletonTheme>
            ) : teamArticleDetails?.docs?.length <= 0 ? (
              <AdvNotFound>
                <div className="not_found_wrapper">
                  <h3 class="team-one__name">
                    <a style={{ color: '#283734' }}>No Team found</a>
                  </h3>
                </div>
              </AdvNotFound>
            ) : (
              teamArticleDetails?.docs?.map((item, key) => (
                <div
                  class="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
                  data-wow-delay="100ms"
                >
                  <div class="team-one__single">
                    <div class="team-one__img">
                      <img
                        src={item?.cover_pic}
                        alt=""
                        className="teamImage"
                        style={{ objectFit: 'cover' }}
                      />
                      <div class="team-one__social">
                        <a href="#">
                          <i class="fab fa-facebook"></i>
                        </a>
                        <a href="#">
                          <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#">
                          <i class="fab fa-pinterest-p"></i>
                        </a>
                        <a href="#">
                          <i class="fab fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                    <div class="team-one__content">
                      <h3 class="team-one__name">
                        <a href="javascript:void(0);">{`${item?.firstname} ${item?.lastname}`}</a>
                      </h3>
                      <p class="team-one__sub-title">{`${item?.office}`}</p>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </section>

      <section class="google-map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3941.490613999345!2d7.556322174779392!3d8.926855691130484!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e0fccd521c6cb%3A0x25ae3b3e456b34b9!2sNyanya-Karshi%20Road%2C%20AMAC%2C%20Federal%20Capital%20Territory!5e0!3m2!1sen!2sng!4v1706909631242!5m2!1sen!2sng"
          // width="600"
          // height="450"
          class="google-map__one"
          style={{ border: 0 }}
          allowfullscreen
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </section>

      <section class="contact-one">
        <div class="container">
          <div class="contact-one__inne">
            <ul class="list-unstyled contact-one__list">
              <li class="contact-one__single">
                <div class="contact-one__icon">
                  <span class="icon-phone-call"></span>
                </div>
                <div class="contact-one__content">
                  <p class="contact-one__sub-title">Helpline</p>
                  <h3 class="contact-one__number">
                    <a href="tel:980009630">+2348157858222</a>
                  </h3>
                </div>
              </li>
              <li class="contact-one__single">
                <div class="contact-one__icon">
                  <span class="icon-message"></span>
                </div>
                <div class="contact-one__content">
                  <p class="contact-one__sub-title">Send email</p>
                  <h3 class="contact-one__number">
                    <a href="mailto:needhelp@company.com">
                      needhelp@company.com
                    </a>
                  </h3>
                </div>
              </li>
              <li class="contact-one__single">
                <div class="contact-one__icon">
                  <span class="icon-location"></span>
                </div>
                <div class="contact-one__content">
                  <p class="contact-one__sub-title">
                    Adjacent to # 7, mid-town hotel Loko,
                  </p>
                  <h3 class="contact-one__number">
                    along Nyanya-Karshi express FCT, Abuja.
                  </h3>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section class="news-two">
        <div class="container">
          <div class="section-title text-center">
            <span class="section-title__tagline">News & articles</span>
            <h2 class="section-title__title">
              Directly from the <br /> latest news and articles
            </h2>
          </div>
          <div class="row">
            {blogLoading ? (
              <SkeletonTheme baseColor="#2A292933" highlightColor="#444">
                <div>
                  <Skeleton width="100vw" height="30rem" />
                </div>
              </SkeletonTheme>
            ) : blogArticleDetails?.docs?.length <= 0 ? (
              <AdvNotFound>
                <div className="not_found_wrapper">
                  <h3 class="team-one__name">
                    <a style={{ color: '#283734' }}>No Blog found</a>
                  </h3>
                </div>
              </AdvNotFound>
            ) : (
              <>
                <div class="col-xl-6 col-lg-4">
                  <div class="news-two__single">
                    <div class="news-two__img-one">
                      <img
                        src={
                          blogArticleDetails?.docs &&
                          blogArticleDetails?.docs[0]?.cover_pic
                        }
                        alt=""
                        style={{
                          objectFit: 'cover',
                          height: '415px',
                        }}
                      />
                      <div class="news-two__date-one">
                        <p>{`${moment(
                          blogArticleDetails?.docs &&
                            blogArticleDetails?.docs[0]?.date
                        ).format('DD MMM, YYYY')}`}</p>
                      </div>
                    </div>
                    <div class="news-two__content-one">
                      <ul class="list-unstyled news-two__meta-one">
                        <li>
                          <a href="news-details.html">
                            <i class="far fa-user-circle"></i> By{' '}
                            {blogArticleDetails?.docs &&
                              blogArticleDetails?.docs[0]?.username}
                          </a>
                        </li>
                      </ul>
                      <h3
                        class="news-two__title-one"
                        style={{
                          overflow: 'hidden',
                          whiteSpace: 'wrap',
                          maxHeight: '5rem',
                          lineClamp: 2,
                          textOverflow: 'ellipsis',
                          textTransform: 'capitalize',
                        }}
                      >
                        <NavLink
                          to={`/blogDetails/${
                            blogArticleDetails?.docs &&
                            blogArticleDetails?.docs[0]?._id
                          }`}
                        >
                          {blogArticleDetails?.docs &&
                            blogArticleDetails?.docs[0]?.title}
                        </NavLink>
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-8">
                  <div class="news-two__single">
                    <ul class="list-unstyled news-two__list">
                      {blogArticleDetails?.docs
                        ?.slice(1, 4)
                        ?.map((item, key) => (
                          <li class="news-two__list-single">
                            <div class="news-two__img-two">
                              <img
                                src={item?.cover_pic}
                                alt=""
                                style={{
                                  objectFit: 'cover',
                                  height: '109px',
                                }}
                              />
                              <div class="news-two__date-two">
                                <p>{`${moment(item?.date).format(
                                  'DD MMM, YYYY'
                                )}`}</p>
                              </div>
                            </div>
                            <div class="news-two__content-two">
                              <ul class="list-unstyled news-two__meta-two">
                                <li>
                                  <a href="news-details.html">
                                    <i class="far fa-user-circle"></i> By{' '}
                                    {item?.username}
                                  </a>
                                </li>
                              </ul>
                              <h3
                                class="news-two__title-two"
                                style={{
                                  overflow: 'hidden',
                                  whiteSpace: 'wrap',
                                  maxHeight: '5rem',
                                  lineClamp: 2,
                                  textOverflow: 'ellipsis',
                                  textTransform: 'capitalize',
                                }}
                              >
                                <NavLink to={`/blogDetails/${item?._id}`}>
                                  {item?.title}
                                </NavLink>
                              </h3>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>

      <section class="brand-one brand-two">
        <div
          class="brand-two-shape-1"
          style={{
            backgroundImage: 'url(assets/images/shapes/brand-two-shape-1.png)',
          }}
        ></div>
        <div class="container">
          <div class="thm-swiper__slider swiper-container">
            {sponsorLoader ? (
              <SkeletonTheme baseColor="#2A292933" highlightColor="#444">
                <div>
                  <Skeleton width="100vw" height="10rem" />
                </div>
              </SkeletonTheme>
            ) : allSponsor?.length <= 0 ? (
              <AdvNotFound>
                <div className="not_found_wrapper">
                  <h3 class="team-one__name">
                    <a style={{ color: '#283734' }}>No Sponsors found</a>
                  </h3>
                </div>
              </AdvNotFound>
            ) : (
              <Swiper
                spaceBetween={100}
                slidesPerView={5}
                //   speed={1500}
                autoplay={{
                  delay: 5000,
                }}
                loop={true}
                breakpoints={{
                  0: {
                    spaceBetween: 30,
                    slidesPerView: 2,
                  },
                  375: {
                    spaceBetween: 30,
                    slidesPerView: 2,
                  },
                  575: {
                    spaceBetween: 30,
                    slidesPerView: 3,
                  },
                  767: {
                    spaceBetween: 50,
                    slidesPerView: 4,
                  },
                  991: {
                    spaceBetween: 50,
                    slidesPerView: 5,
                  },
                  1199: {
                    spaceBetween: 100,
                    slidesPerView: 6,
                  },
                }}
                //   centeredSlides={true}
                modules={[Pagination, Navigation, Autoplay]}
                class="swiper-wrapper"
              >
                {allSponsor?.map((item, key) => (
                  <SwiperSlide class="swiper-slide">
                    <img
                      src={item?.cover_pic}
                      alt=""
                      style={{ height: '5rem' }}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default LandingPage;
