import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import ProjectServices from "./projectServices";

export const GetProject = createAsyncThunk(
  "project/getProject",
  async (payload) => {
    try {
      let response = await ProjectServices.getAlluserProjects(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetSingleProject = createAsyncThunk(
  "project/getSingleProject",
  async (payload) => {
    try {
      let response = await ProjectServices.getSingleProject(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);
