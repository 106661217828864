import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoaderScreen from "../utils/loaderScreen";
import HTMLReactParser from "html-react-parser";
import moment from "moment";
import { GetSingleServices } from "../features/services/servicesServicesSync";
import { useParams } from "react-router-dom";
import { ArticleWrapper } from "./Styles/aritcleWrapper.style";

function ServicesDetails() {
  const dispatch = useDispatch();

  const { services } = useParams();

  const { singleServicesDetails, serviceLoading } = useSelector(
    (state) => state.services
  );

  useEffect(() => {
    dispatch(GetSingleServices(services));
  }, [services]);

  return (
    <ArticleWrapper>
      <section class="page-header">
        <div
          class="page-header-bg"
          style={{
            backgroundImage:
              "url(/assets/images/backgrounds/page-header-bg.jpg)",
          }}
        ></div>
        <div class="container">
          <div class="page-header__inner">
            <ul class="thm-breadcrumb list-unstyled">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>
                <span>/</span>
              </li>
              <li class="active">Event details</li>
            </ul>
            <h2>Event details</h2>
          </div>
        </div>
      </section>
      {serviceLoading ? (
        <LoaderScreen />
      ) : (
        <section class="event-details">
          <div class="container">
            <div class="row">
              <div class="col-xl-8 col-lg-7">
                <div class="event-details__left">
                  <div class="event-details__top">
                    <div class="event-details__date">
                      <p>
                        {`${moment(singleServicesDetails?.date).format(
                          "DD MMM, YYYY"
                        )}`}
                      </p>
                    </div>
                    <h3 class="event-details__title">
                      {singleServicesDetails?.title}
                    </h3>
                    <p class="event-details__text-1 content">
                      {HTMLReactParser(
                        HTMLReactParser(`${singleServicesDetails?.article}`)
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-5">
                <div class="event-details__right">
                  <div class="event-details__info">
                    <ul class="list-unstyled event-details__info-list">
                      <li>
                        <span>Time:</span>
                        <p>8:00 am to 12:30 pm</p>
                      </li>
                      <li>
                        <span>Date:</span>
                        <p>{`${moment(singleServicesDetails?.date).format(
                          "DD MMM, YYYY"
                        )}`}</p>
                      </li>
                      <li>
                        <span>Category:</span>
                        <p>{singleServicesDetails?.hash_tag}</p>
                      </li>
                    </ul>
                    <div class="event-details__social">
                      <a href="#">
                        <i class="fab fa-twitter"></i>
                      </a>
                      <a href="#">
                        <i class="fab fa-facebook"></i>
                      </a>
                      <a href="#">
                        <i class="fab fa-pinterest-p"></i>
                      </a>
                      <a href="#">
                        <i class="fab fa-instagram"></i>
                      </a>
                    </div>
                  </div>
                  <div class="event-details__map">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3941.490613999345!2d7.556322174779392!3d8.926855691130484!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e0fccd521c6cb%3A0x25ae3b3e456b34b9!2sNyanya-Karshi%20Road%2C%20AMAC%2C%20Federal%20Capital%20Territory!5e0!3m2!1sen!2sng!4v1706909631242!5m2!1sen!2sng"
                      class="event-details-map__one"
                      style={{ border: 0 }}
                      allowfullscreen
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </ArticleWrapper>
  );
}

export default ServicesDetails;
