import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Bars, InfinitySpin } from "react-loader-spinner";
import { ImageUpload } from "./Styles/imageUpload.style";
import ProfileAvatar from "../Assets/avatar-profile-photo.png";
import { FiUploadCloud } from "react-icons/fi";
import { ThreeLoaderAppColor } from "../utils/threeLoader";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateTestimonyRequest,
  uploadImageTestimony,
} from "../features/testimony/testimonyServicesSync";
import { updateCoverPic } from "../features/testimony/testimonySlices";

const baseSchema = Yup.object({
  firstname: Yup.string().required(),
  lastname: Yup.string().required(),
  email: Yup.string().email().required(),
  address: Yup.string().required(),
  phone_number: Yup.string().required(),
  office: Yup.string().required(),
  gender: Yup.string().required(),
  about: Yup.string().required(),
  user_type: Yup.string().required(),
});

function CreateTestimony() {
  const dispatch = useDispatch();

  const [images, setImages] = useState();

  const { contentDetails, isPicLoading, testimonyLoading } = useSelector(
    (state) => state.testimony
  );

  function getbase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  const handleCoverImage = (e) => {
    const image = e.target.files[0];

    if (image) {
      let promise = getbase64(image);
      promise
        .then((data) => {
          dispatch(uploadImageTestimony({ file: data }));
          return data;
        })
        .then((res) => {
          // const linv = cre
          setImages(res);
        });
    }
  };

  const handleSubmited = async (value, { resetForm }) => {
    await dispatch(
      CreateTestimonyRequest({
        ...value,
        cover_pic: contentDetails?.secure_url,
        cover_pic_id: contentDetails?.public_id,
      })
    );

    setImages();

    dispatch(updateCoverPic({}));

    resetForm();
  };

  const { values, handleBlur, handleChange, handleSubmit, errors } = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      address: "",
      phone_number: "",
      office: "",
      gender: "",
      about: "",
      user_type: "",
    },
    validationSchema: baseSchema,
    onSubmit: handleSubmited,
  });

  return (
    <ImageUpload>
      <div class="stricky-header stricked-menu main-menu">
        <div class="sticky-header__content"></div>
      </div>

      <section class="page-header">
        <div
          class="page-header-bg"
          style={{
            backgroundImage:
              "url(assets/images/backgrounds/page-header-bg.jpg)",
          }}
        ></div>
        <div class="container">
          <div class="page-header__inner">
            <ul class="thm-breadcrumb list-unstyled">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>
                <span>/</span>
              </li>
              <li class="active">Pages</li>
            </ul>
            <h2>Testify About Us</h2>
          </div>
        </div>
      </section>

      <section class="become-volunteer-page">
        <div class="container">
          <div class="section-title text-center">
            <span class="section-title__tagline">Testify About Us</span>
            <h2 class="section-title__title">
              Let’s join our community to <br /> become a volunteer
            </h2>
          </div>
          <div class="row">
            <div class="col-xl-6 col-lg-6">
              <div class="become-volunteer-page__left">
                <div class="become-volunteer-page__img">
                  <img
                    src="assets/images/team/become-volunteer-img-1.jpg"
                    alt=""
                  />
                </div>
                <div class="become-volunteer-page__content">
                  <h3 class="become-volunteer-page__title">Requirements</h3>
                  <p class="become-volunteer-page__text">
                    Aliquam hendrerit a augue insu image pellentes que id erat
                    quis sollicitud null mattis Ipsum is simply dummy
                    typesetting industry. Alienum phaedrum torquatos nec eu.
                  </p>
                  <ul class="list-unstyled become-volunteer__points">
                    <li>
                      <div class="icon">
                        <i class="fa fa-check"></i>
                      </div>
                      <div class="text">
                        <p>Making this first true generator simply text</p>
                      </div>
                    </li>
                    <li>
                      <div class="icon">
                        <i class="fa fa-check"></i>
                      </div>
                      <div class="text">
                        <p>Lorem Ipsum is not simply free text</p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="become-volunteer__call">
                  <div class="become-volunteer__call-icon">
                    <span class="icon-chat"></span>
                  </div>
                  <div class="become-volunteer__call-content">
                    <span>Call Anytime</span>
                    <p>
                      <a href="tel:980009630">+98 (000) - 9630</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6">
              <div class="become-volunteer-page__right">
                <form
                  onSubmit={handleSubmit}
                  class="become-volunteer-page__form"
                >
                  <div class="row">
                    <div class="profile_input_field_input_image_avatar">
                      <img
                        src={
                          Object.keys(contentDetails).length > 0
                            ? contentDetails?.secure_url
                            : images
                            ? images
                            : ProfileAvatar
                        }
                        alt="see"
                        class="profile_input_field_input_image_avartar_card"
                      />

                      <div class="profile_input_field_input_image_avatar_upload_card">
                        <div class="profile_input_field_input_image_avatar_upload_card_icon_body">
                          <FiUploadCloud class="profile_input_field_input_image_avatar_upload_card_icon" />
                        </div>

                        {isPicLoading ? (
                          <ThreeLoaderAppColor />
                        ) : (
                          <p>Upload Photo</p>
                        )}

                        <input
                          type="file"
                          class="fileUpload"
                          onChange={(e) => handleCoverImage(e)}
                        />
                      </div>
                    </div>

                    <div class="col-xl-6">
                      <div class="become-volunteer-page__input">
                        <input
                          type="text"
                          placeholder="First Name"
                          name="firstname"
                          value={values?.firstname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{
                            border: errors.firstname ? `1px solid red` : `none`,
                          }}
                        />
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="become-volunteer-page__input">
                        <input
                          type="text"
                          placeholder="Last Name"
                          name="lastname"
                          value={values?.lastname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{
                            border: errors.lastname ? `1px solid red` : `none`,
                          }}
                        />
                      </div>
                    </div>
                    <div class="col-xl-12">
                      <div class="become-volunteer-page__input">
                        <input
                          type="email"
                          placeholder="Email Address"
                          name="email"
                          value={values?.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{
                            border: errors.email ? `1px solid red` : `none`,
                          }}
                        />
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="become-volunteer-page__input">
                        <input
                          type="text"
                          placeholder="Gender"
                          name="gender"
                          value={values?.gender}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{
                            border: errors.gender ? `1px solid red` : `none`,
                          }}
                        />
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="become-volunteer-page__input">
                        <input
                          type="text"
                          placeholder="Phone Number"
                          name="phone_number"
                          value={values?.phone_number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{
                            border: errors.phone_number
                              ? `1px solid red`
                              : `none`,
                          }}
                        />
                      </div>
                    </div>
                    <div class="col-xl-12">
                      <div class="become-volunteer-page__input">
                        <input
                          type="text"
                          placeholder="Address"
                          name="address"
                          value={values?.address}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{
                            border: errors.address ? `1px solid red` : `none`,
                          }}
                        />
                      </div>
                    </div>
                    <div class="col-xl-12">
                      <div class="become-volunteer-page__input">
                        <input
                          type="text"
                          placeholder="User Type (e.g Visitor, Volunteer, User etc.)"
                          name="user_type"
                          value={values?.user_type}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{
                            border: errors.user_type ? `1px solid red` : `none`,
                          }}
                        />
                      </div>
                    </div>
                    <div class="col-xl-12">
                      <div class="become-volunteer-page__input">
                        <input
                          type="text"
                          placeholder="Occupation"
                          name="office"
                          value={values?.office}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{
                            border: errors.office ? `1px solid red` : `none`,
                          }}
                        />
                      </div>
                    </div>

                    <div class="col-xl-12">
                      <div class="become-volunteer-page__input become-volunteer__message-box">
                        <textarea
                          name="about"
                          placeholder="Write a Comment"
                          value={values?.about}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{
                            border: errors.about ? `1px solid red` : `none`,
                          }}
                        ></textarea>
                      </div>
                      <div class="become-volunteer-page__btn-box">
                        {testimonyLoading ? (
                          <InfinitySpin width="150" color="#0716A5" />
                        ) : (
                          <button
                            type="submit"
                            class="thm-btn become-volunteer-page__btn"
                          >
                            Send a message
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ImageUpload>
  );
}

export default CreateTestimony;
