import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { GetBlog } from '../features/blog/blogServicesSync';
import { reset, updateBlogPage } from '../features/blog/blogSlices';
import { DomPaginationState } from '../Components/DOM_Pagination_State';
import LoaderScreen from '../utils/loaderScreen';
import moment from 'moment';
import { NotFound } from './Styles/notFound';

function Blog() {
  const dispatch = useDispatch();

  const { blogArticleDetails, blogLoading, bloglimit, blogPage } = useSelector(
    (state) => state.blog
  );

  useEffect(() => {
    dispatch(GetBlog({ page: blogPage, limit: bloglimit }));

    return () => {
      dispatch(reset());
    };
  }, []);

  const nextPage = () => {
    if (!blogLoading) {
      if (blogArticleDetails?.hasNextPage) {
        dispatch(updateBlogPage(blogPage + 1));

        dispatch(
          GetBlog({
            page: blogPage + 1,
            limit: bloglimit,
          })
        );
      }
    }
  };

  const prevPage = () => {
    if (!blogLoading) {
      if (blogArticleDetails?.hasPrevPage) {
        dispatch(updateBlogPage(blogPage - 1));

        dispatch(
          GetBlog({
            page: blogPage - 1,
            limit: bloglimit,
          })
        );
      }
    }
  };

  return (
    <>
      <section class="page-header">
        <div
          class="page-header-bg"
          style={{
            backgroundImage:
              'url(assets/images/backgrounds/page-header-bg.jpg)',
          }}
        ></div>
        <div class="container">
          <div class="page-header__inner">
            <ul class="thm-breadcrumb list-unstyled">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>
                <span>/</span>
              </li>
              <li class="active">Blogs</li>
            </ul>
            <h2>Blogs</h2>
          </div>
        </div>
      </section>
      <section class="news-page">
        <div class="container">
          <div class="row">
            {blogLoading ? (
              <LoaderScreen />
            ) : blogArticleDetails?.docs?.length > 0 ? (
              blogArticleDetails?.docs?.map((item, key) => (
                <div
                  key={key}
                  class="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
                  data-wow-delay="100ms"
                >
                  <div class="news-one__single">
                    <div class="news-one__img">
                      <img
                        src={item?.cover_pic}
                        alt=""
                        style={{
                          objectFit: 'cover',
                          height: '300px',
                        }}
                      />
                    </div>
                    <div class="news-one__content-box">
                      <div class="news-one__content-inner">
                        <div class="news-one__content">
                          <ul class="list-unstyled news-one__meta">
                            <li>
                              <a>
                                <i class="far fa-user-circle"></i>{' '}
                                {item?.username}
                              </a>
                            </li>
                            {/* <li>
                              <a href="news-details.html">
                                <i class="fas fa-comments"></i> 2 Comments
                              </a>
                            </li> */}
                          </ul>
                          <h3
                            class="news-one__title"
                            style={{
                              overflow: 'hidden',
                              whiteSpace: 'wrap',
                              maxHeight: '5rem',
                              lineClamp: 2,
                              textOverflow: 'ellipsis',
                              textTransform: 'capitalize',
                            }}
                          >
                            <NavLink to={`/blogDetails/${item?._id}`}>
                              {item?.title}
                            </NavLink>
                          </h3>
                        </div>
                        <div class="news-one__bottom">
                          <div class="news-one__read-more">
                            <NavLink to={`/blogDetails/${item?._id}`}>
                              <span class="icon-right-arrow"></span> Read More
                            </NavLink>
                          </div>
                        </div>
                      </div>

                      <div class="news-one__date">
                        <p>{`${moment(item?.date).format('DD MMM, YYYY')}`}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <NotFound>
                <div className="not_found_wrapper">
                  <h3 class="team-one__name">
                    <a style={{ color: '#283734' }}>No Blog found</a>
                  </h3>
                </div>
              </NotFound>
            )}

            <DomPaginationState
              onNext={nextPage}
              onPrev={prevPage}
              skip={blogArticleDetails?.page}
              loading={blogLoading}
              hasNext={blogArticleDetails?.hasNextPage}
              hasPrev={blogArticleDetails?.hasPrevPage}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default Blog;
