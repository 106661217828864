import { createSlice } from '@reduxjs/toolkit';
import { GetBlog, GetSingleBlog, PostComment } from './blogServicesSync';

const initialState = {
  isError: false,
  isSuccess: false,
  message: '',
  blogLoading: false,
  singleBlogDetails: {},
  blogArticleDetails: {},
  bloglimit: 9,
  blogPage: 1,
  allBlogComment: {},
  commentLoader: false,
  commentMessage: '',
};

export const blogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
      state.blogLoading = false;
      state.singleBlogDetails = {};
      state.blogArticleDetails = {};
      state.bloglimit = 9;
      state.blogPage = 1;
      state.allBlogComment = {};
      state.commentLoader = false;
      state.commentMessage = '';
    },

    updateBlogPage: (state, action) => {
      state.blogPage = action.payload;
    },
  },
  extraReducers: {
    [GetBlog.pending]: (state, action) => {
      if (state?.blogArticleDetails?.docs?.length <= 0) {
        state.blogLoading = true;
      }
    },
    [GetBlog.fulfilled]: (state, action) => {
      state.blogLoading = false;
      state.message = action.payload?.message;
      state.blogArticleDetails = action?.payload?.data;
    },
    [GetBlog.rejected]: (state, action) => {
      state.blogLoading = false;
      state.message = action.payload?.message;
    },

    [GetSingleBlog.pending]: (state, action) => {
      state.blogLoading = true;
    },
    [GetSingleBlog.fulfilled]: (state, action) => {
      state.blogLoading = false;
      state.message = action.payload[0]?.message;
      state.singleBlogDetails = action.payload[0]?.data;
      state.allBlogComment = action.payload[1]?.data;
    },
    [GetSingleBlog.rejected]: (state, action) => {
      state.blogLoading = false;
      state.message = action.payload?.message;
    },

    [PostComment.pending]: (state, action) => {
      state.commentLoader = true;
    },

    [PostComment.fulfilled]: (state, action) => {
      const payload = {
        ...state.allBlogComment,
        data: [...state.allBlogComment?.data, action.payload?.data],
        count: state.allBlogComment?.count + 1,
      };

      state.commentLoader = false;
      state.commentMessage = action.payload?.message;
      state.allBlogComment = payload;
    },

    [PostComment.rejected]: (state, action) => {
      state.commentLoader = false;
      state.commentMessage = action.payload?.message;
    },
  },
});

export const { reset, updateBlogPage } = blogSlice.actions;

export default blogSlice.reducer;
