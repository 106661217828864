import styled from "styled-components";

export const FooterDiv = styled.div`
  .rowDetails {
    width: 100%;

    .rowItemCenter {
      display: flex;
      align-items: center;
      flex-direction: column;

      @media screen and (max-width: 768px) {
        display: unset;
        align-items: unset;
        flex-direction: unset;
      }
    }
  }
`;
