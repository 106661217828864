import { createSlice } from '@reduxjs/toolkit';
import { GetFaq } from './faqServicesSync';

const initialState = {
  isError: false,
  isSuccess: false,
  message: '',
  faqLoading: false,
  faqArticleDetails: {},
  faqlimit: 10,
  blogPage: 1,
};

export const faqSlice = createSlice({
  name: 'faq',
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
      state.faqLoading = false;
      state.faqArticleDetails = {};
      state.faqlimit = 20;
      state.faqPage = 1;
    },

    updateFaqPage: (state, action) => {
      state.faqPage = action.payload;
    },
  },
  extraReducers: {
    [GetFaq.pending]: (state, action) => {
      if (state?.faqArticleDetails?.docs?.length <= 0) {
        state.faqLoading = true;
      }
    },
    [GetFaq.fulfilled]: (state, action) => {
      state.faqLoading = false;
      state.message = action.payload?.message;
      state.faqArticleDetails = action?.payload?.data;
    },
    [GetFaq.rejected]: (state, action) => {
      state.faqLoading = false;
      state.message = action.payload?.message;
    },
  },
});

export const { reset, updateFaqPage } = faqSlice.actions;

export default faqSlice.reducer;
