import { createSlice } from '@reduxjs/toolkit';
import { GetResource, GetSingleResource } from './resourcesServicesSync';

const initialState = {
  isError: false,
  isSuccess: false,
  message: '',
  resourceLoading: false,
  singleResourcesDetails: {},
  resourcesArticleDetails: {},
  resourceslimit: 9,
  resourcesPage: 1,
};

export const resourcesSlices = createSlice({
  name: 'resource',
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
      state.resourceLoading = false;
      state.singleResourcesDetails = {};
      state.resourcesArticleDetails = {};
      state.resourceslimit = 9;
      state.resourcesPage = 1;
    },

    updateResourcesPage: (state, action) => {
      state.resourcesPage = action.payload;
    },
  },
  extraReducers: {
    [GetResource.pending]: (state, action) => {
      if (state.resourcesArticleDetails?.docs?.length <= 0) {
        state.resourceLoading = true;
      }
    },
    [GetResource.fulfilled]: (state, action) => {
      state.resourceLoading = false;
      state.message = action.payload?.message;
      state.resourcesArticleDetails = action?.payload?.data;
    },
    [GetResource.rejected]: (state, action) => {
      state.resourceLoading = false;
      state.message = action.payload?.message;
    },

    [GetSingleResource.pending]: (state, action) => {
      state.resourceLoading = true;
    },
    [GetSingleResource.fulfilled]: (state, action) => {
      state.resourceLoading = false;
      state.message = action.payload?.message;
      state.singleResourcesDetails = action.payload?.data;
    },
    [GetSingleResource.rejected]: (state, action) => {
      state.resourceLoading = false;
      state.message = action.payload?.message;
    },
  },
});

export const { reset, updateResourcesPage } = resourcesSlices.actions;

export default resourcesSlices.reducer;
