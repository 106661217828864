import styled from "styled-components";

export const ImageUpload = styled.div`
  .profile_input_field_input_image_avatar {
    position: relative;
    width: 260px;
    height: 270px;
    margin-bottom: 40px;

    .profile_input_field_input_image_avartar_card {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }

    .profile_input_field_input_image_avatar_upload_card {
      position: absolute;
      left: 20px;
      bottom: 30px;
      border: 1px solid red;
      display: flex;
      align-items: center;
      background-color: #ffffff;
      border-radius: 8px;
      padding: 5px 10px;
      gap: 10px;
      cursor: pointer;

      .profile_input_field_input_image_avatar_upload_card_icon_body {
        display: flex;
        align-items: center;
        justify-content: center;

        .profile_input_field_input_image_avatar_upload_card_icon {
          color: #ff8f7e;
          font-size: 24px;
        }
      }

      p {
        margin: 0;
        color: #101828;
        font-size: 14px;
        /* font-family: Roboto; */
        font-weight: 400;
        line-height: 30px;
      }

      .fileUpload {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: 5;
        cursor: pointer;
      }
    }

    @media screen and (max-width: 740px) {
      width: 200px;
      height: 210px;
    }
  }
`;
