import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { GetResource } from "../features/resourse/resourcesServicesSync";
import {
  reset,
  updateResourcesPage,
} from "../features/resourse/resourcesSlices";
import { DomPaginationState } from "../Components/DOM_Pagination_State";
import LoaderScreen from "../utils/loaderScreen";
import moment from "moment";
import { NotFound } from "./Styles/notFound";

function Resource() {
  const dispatch = useDispatch();

  const {
    resourcesArticleDetails,
    resourceLoading,
    resourceslimit,
    resourcesPage,
  } = useSelector((state) => state.resource);

  useEffect(() => {
    dispatch(GetResource({ page: resourcesPage, limit: resourceslimit }));

    return () => {
      dispatch(reset());
    };
  }, []);

  const nextPage = () => {
    if (!resourceLoading) {
      if (resourcesArticleDetails?.hasNextPage) {
        dispatch(updateResourcesPage(resourcesPage + 1));

        dispatch(
          GetResource({
            page: resourcesPage + 1,
            limit: resourceslimit,
          })
        );
      }
    }
  };

  const prevPage = () => {
    if (!resourceLoading) {
      if (resourcesArticleDetails?.hasPrevPage) {
        dispatch(updateResourcesPage(resourcesPage - 1));

        dispatch(
          GetResource({
            page: resourcesPage - 1,
            limit: resourceslimit,
          })
        );
      }
    }
  };
  return (
    <>
      <section class="page-header">
        <div
          class="page-header-bg"
          style={{
            backgroundImage:
              "url(assets/images/backgrounds/page-header-bg.jpg)",
          }}
        ></div>
        <div class="container">
          <div class="page-header__inner">
            <ul class="thm-breadcrumb list-unstyled">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>
                <span>/</span>
              </li>
              <li class="active">Resources</li>
            </ul>
            <h2>Resources</h2>
          </div>
        </div>
      </section>
      <section class="events-page">
        <div class="container">
          <div class="row">
            {resourceLoading ? (
              <LoaderScreen />
            ) : resourcesArticleDetails?.docs?.length > 0 ? (
              resourcesArticleDetails?.docs?.map((item, key) => (
                <div class="col-xl-4 col-lg-6 col-md-6" key={key}>
                  <div class="events-one__single">
                    <div class="events-one__img">
                      <img
                        src={item?.cover_pic}
                        alt=""
                        style={{ objectFit: "cover", height: "500px" }}
                      />
                      <div class="events-one__date">
                        <p>{`${moment(item?.date).format("DD MMM, YYYY")}`}</p>
                      </div>
                      <div class="events-one__content">
                        <ul class="list-unstyled events-one__meta">
                          <li>
                            <i class="fas fa-clock"></i>
                            {`${moment(item?.date).format("h:mma")}`}
                          </li>
                          <li>
                            <i class="fas fa-map-marker-alt"></i>
                            {item?.location?.slice(0, 14)}
                          </li>
                        </ul>
                        <h3
                          class="events-one__title"
                          style={{
                            overflow: "hidden",
                            whiteSpace: "wrap",
                            maxHeight: "2rem",
                            lineClamp: 1,
                            textOverflow: "ellipsis",
                          }}
                        >
                          <NavLink to={`/resourse/${item?._id}`}>
                            {item?.title}
                          </NavLink>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <NotFound>
                <div className="not_found_wrapper">
                  <h3 class="team-one__name">
                    <a href="team-details.html" style={{ color: "#283734" }}>
                      No Resource found
                    </a>
                  </h3>
                </div>
              </NotFound>
            )}

            {resourcesArticleDetails?.docs?.length > 0 && (
              <DomPaginationState
                onNext={nextPage}
                onPrev={prevPage}
                skip={resourcesArticleDetails?.page}
                loading={resourceLoading}
                hasNext={resourcesArticleDetails?.hasNextPage}
                hasPrev={resourcesArticleDetails?.hasPrevPage}
              />
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default Resource;
