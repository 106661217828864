import styled from 'styled-components';

export const NotFound = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .not_found_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;

    p {
      font-size: 15px;
      font-weight: 400;
      margin: 0;
      color: #101828;
    }

    button {
      border: none;
      outline: none;
      background-color: #007bff;
      padding: 4px 49px;
      color: white;
      font-size: 16px;
      /* font-family: Roboto; */
      font-weight: 500;
      line-height: 30px;
      border-radius: 20px;
      cursor: pointer;
    }
  }
`;

export const AdvNotFound = styled.div`
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .not_found_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;

    p {
      font-size: 15px;
      font-weight: 400;
      margin: 0;
      color: #101828;
      border: '1px solid red';
    }

    button {
      border: none;
      outline: none;
      background-color: #007bff;
      padding: 4px 49px;
      color: white;
      font-size: 16px;
      /* font-family: Roboto; */
      font-weight: 500;
      line-height: 30px;
      border-radius: 20px;
      cursor: pointer;
    }
  }
`;
