import { createSlice } from '@reduxjs/toolkit';
import { GetSponsors } from './sponsorServicesSync';

const initialState = {
  isError: false,
  isSuccess: false,
  sponsorLoader: false,
  message: '',
  allSponsor: [],
};

export const sponsorSlice = createSlice({
  name: 'sponsor',
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
      state.sponsorLoader = false;
      state.allSponsor = [];
    },

    checkMessage: (state, action) => {
      state.message = action.payload;
    },
  },
  extraReducers: {
    [GetSponsors.pending]: (state, action) => {
      if (state.allSponsor?.length <= 0) {
        state.sponsorLoader = true;
      }
    },

    [GetSponsors.fulfilled]: (state, action) => {
      state.sponsorLoader = false;
      state.message = action.payload?.message;
      state.allSponsor = action?.payload?.data;
    },

    [GetSponsors.rejected]: (state, action) => {
      state.sponsorLoader = false;
      state.message = action.payload?.message;
    },
  },
});

export const { reset, checkMessage } = sponsorSlice.actions;

export default sponsorSlice.reducer;
