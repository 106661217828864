import React, { useEffect } from "react";
import {
  GetGallery,
  GetGalleryAdv,
} from "../features/gallery/galleryServicesSync";
import { reset, updateGalleryPage } from "../features/gallery/gallerySlices";
import { useDispatch, useSelector } from "react-redux";
import LoaderScreen from "../utils/loaderScreen";
import { DomPaginationState } from "../Components/DOM_Pagination_State";
import { NotFound } from "./Styles/notFound";

function Gallery() {
  const dispatch = useDispatch();

  const {
    allGallery,
    paginationLoader,
    contentLoader,
    gallerylimit,
    galleryPage,
  } = useSelector((state) => state.gallery);

  useEffect(() => {
    dispatch(GetGallery({ page: galleryPage, limit: gallerylimit }));

    return () => {
      dispatch(reset());
    };
  }, []);

  const nextPage = () => {
    if (!paginationLoader) {
      if (allGallery?.hasNextPage) {
        dispatch(updateGalleryPage(galleryPage + 1));

        dispatch(
          GetGalleryAdv({
            page: galleryPage + 1,
            limit: gallerylimit,
          })
        );
      }
    }
  };

  const prevPage = () => {
    if (!paginationLoader) {
      if (allGallery?.hasPrevPage) {
        dispatch(updateGalleryPage(galleryPage - 1));

        dispatch(
          GetGalleryAdv({
            page: galleryPage - 1,
            limit: gallerylimit,
          })
        );
      }
    }
  };

  return (
    <>
      <section class="page-header">
        <div
          class="page-header-bg"
          style={{
            backgroundImage:
              "url(assets/images/backgrounds/page-header-bg.jpg)",
          }}
        ></div>
        <div class="container">
          <div class="page-header__inner">
            <ul class="thm-breadcrumb list-unstyled">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>
                <span>/</span>
              </li>
              <li class="active">Pages</li>
            </ul>
            <h2>Gallery</h2>
          </div>
        </div>
      </section>
      <section class="gallery-page">
        <div class="container">
          <div class="row">
            {contentLoader ? (
              <LoaderScreen />
            ) : allGallery?.docs?.length > 0 ? (
              allGallery?.docs?.map((item, key) => (
                <div class="col-xl-4 col-lg-6 col-md-6" key={key}>
                  <div class="gallery-page__single">
                    <div class="gallery-page__img">
                      <img
                        src={item?.cover_pic}
                        alt=""
                        style={{
                          objectFit: "cover",
                          height: "430px",
                        }}
                      />
                      <a
                        href="assets/images/gallery/gallery-page-1-1.jpg"
                        class="img-popup"
                      ></a>

                      <div class="gallery-page__content">
                        {/* <p class="gallery-page__sub-title">Charity</p> */}
                        <h5 class="gallery-page__title">
                          <a href="gallery.html">{item?.title}</a>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <NotFound>
                <div className="not_found_wrapper">
                  <h3 class="team-one__name">
                    <a href="team-details.html" style={{ color: "#283734" }}>
                      No Gallery found
                    </a>
                  </h3>
                </div>
              </NotFound>
            )}

            <DomPaginationState
              onNext={nextPage}
              onPrev={prevPage}
              skip={allGallery?.page}
              loading={paginationLoader}
              hasNext={allGallery?.hasNextPage}
              hasPrev={allGallery?.hasPrevPage}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default Gallery;
