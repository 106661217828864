import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import testimonyServices from './testimonyServices';

export const uploadImageTestimony = createAsyncThunk(
  'testimony/uploadimage',
  async (payload) => {
    try {
      let response = await testimonyServices.testimonyImageUpload(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const CreateTestimonyRequest = createAsyncThunk(
  'testimony/createTestimony',
  async (payload) => {
    try {
      let response = await testimonyServices.CreateTestimony(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetTestimony = createAsyncThunk(
  'testimony/getTestimonys',
  async (payload) => {
    try {
      console.log(payload, 'storpjdk');
      let response = await testimonyServices.getTestimonyList(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);
