import { createAsyncThunk } from "@reduxjs/toolkit";
import DonationServices from "./donationServices";
import { toast } from "react-toastify";
import { PayStack } from "../../utils/baseUrl";

export const InitializeDonation = createAsyncThunk(
  "donation/initialize_donation",
  async (payload) => {
    try {
      let response = await DonationServices.InitializeDonate(payload);

      console.log(response?.data?.data?.access_code, "esee ");

      const url = `${PayStack}/${response?.data?.data?.access_code}`;

      // Navigate to the URL
      window.location.href = url;

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);
