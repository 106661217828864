import { createSlice } from '@reduxjs/toolkit';
import {
  EditGallery,
  GalleryUploadImage,
  GetGallery,
  GetGalleryAdv,
  GetMyCreatedGallery,
  GetMyCreatedGalleryAdv,
  PostGallery,
} from './galleryServicesSync';

const initialState = {
  isError: false,
  isSuccess: false,
  contentLoader: false,
  message: '',
  allGallery: {},
  paginationLoader: false,
  gallerylimit: 9,
  galleryPage: 1,
};

export const gallerySlice = createSlice({
  name: 'gallery',
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
      state.contentLoader = false;
      state.allGallery = {};
      state.gallerylimit = 9;
      state.galleryPage = 1;
      state.paginationLoader = false;
    },

    updateGalleryPage: (state, action) => {
      state.galleryPage = action.payload;
    },
  },
  extraReducers: {
    [GetGallery.pending]: (state, action) => {
      if (state.allGallery?.docs?.length <= 0) {
        state.contentLoader = true;
      }
    },

    [GetGallery.fulfilled]: (state, action) => {
      state.contentLoader = false;
      state.message = action.payload?.message;
      state.allGallery = action?.payload?.data;
    },

    [GetGallery.rejected]: (state, action) => {
      state.contentLoader = false;
      state.message = action.payload?.message;
    },

    [GetGalleryAdv.pending]: (state, action) => {
      state.paginationLoader = true;
    },

    [GetGalleryAdv.fulfilled]: (state, action) => {
      state.paginationLoader = false;
      state.message = action.payload?.message;
      state.allGallery = action?.payload?.data;
    },

    [GetGalleryAdv.rejected]: (state, action) => {
      state.paginationLoader = false;
      state.message = action.payload?.message;
    },
  },
});

export const { reset, updateGalleryPage } = gallerySlice.actions;

export default gallerySlice.reducer;
