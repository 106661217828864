import APIs from '../apiUrl';

const getFaq = async (payload) => {
  try {
    const response = await APIs.get(
      `/get_enduser_faq/${payload.page}/${payload.limit}`
    );

    if (response?.data?.message === 'success') {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const FaqServices = {
  getFaq,
};

export default FaqServices;
