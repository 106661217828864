import { createSlice } from '@reduxjs/toolkit';
import { InitializeDonation } from './donationServicesSync';

const initialState = {
  isError: false,
  isSuccess: false,
  message: '',
  donationLoading: false,
  donateDetails: {},
};

export const donationSlice = createSlice({
  name: 'donation',
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
      state.donationLoading = false;
      state.donateDetails = {};
    },

    updateDonateDetails: (state, action) => {
      state.donateDetails = action.payload;
    },
  },
  extraReducers: {
    [InitializeDonation.pending]: (state, action) => {
      state.donationLoading = true;
    },

    [InitializeDonation.fulfilled]: (state, action) => {
      state.donationLoading = false;
      state.message = action.payload?.message;
    },

    [InitializeDonation.rejected]: (state, action) => {
      state.donationLoading = false;
      state.message = action.payload?.message;
    },
  },
});

export const { reset, updateDonateDetails } = donationSlice.actions;

export default donationSlice.reducer;
