import { createSlice } from '@reduxjs/toolkit';
import {
  CreateTestimonyRequest,
  GetTestimony,
  uploadImageTestimony,
} from './testimonyServicesSync';

const initialState = {
  isError: false,
  isSuccess: false,
  message: '',
  testimonyLoading: false,
  testimonyArticleDetails: {},
  testimonylimit: 9,
  testimonyPage: 1,
  isPicLoading: false,
  contentDetails: {},
};

export const testimonySlices = createSlice({
  name: 'testimony',
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
      state.testimonyLoading = false;
      state.testimonyArticleDetails = {};
      state.testimonylimit = 9;
      state.testimonyPage = 1;
      state.isPicLoading = false;
      state.contentDetails = {};
    },

    updatetesTimonyPage: (state, action) => {
      state.testimonyPage = action.payload;
    },

    updateCoverPic: (state, action) => {
      state.contentDetails = action.payload;
    },
  },
  extraReducers: {
    [uploadImageTestimony.pending]: (state, action) => {
      state.isPicLoading = true;
    },

    [uploadImageTestimony.fulfilled]: (state, action) => {
      state.isPicLoading = false;
      state.message = action.payload?.message;
      state.contentDetails = action.payload?.data;
    },

    [uploadImageTestimony.rejected]: (state, action) => {
      state.isPicLoading = false;
      state.message = action.payload?.message;
    },
    [GetTestimony.pending]: (state, action) => {
      if (state.testimonyArticleDetails?.docs.length <= 0) {
        state.testimonyLoading = true;
      }
    },
    [GetTestimony.fulfilled]: (state, action) => {
      state.testimonyLoading = false;
      state.message = action.payload?.message;
      state.testimonyArticleDetails = action?.payload?.data;
    },
    [GetTestimony.rejected]: (state, action) => {
      state.testimonyLoading = false;
      state.message = action.payload?.message;
    },

    [CreateTestimonyRequest.pending]: (state, action) => {
      state.testimonyLoading = true;
    },

    [CreateTestimonyRequest.fulfilled]: (state, action) => {
      state.testimonyLoading = false;
      state.message = action.payload?.message;
    },

    [CreateTestimonyRequest.rejected]: (state, action) => {
      state.testimonyLoading = false;
      state.message = action.payload?.message;
    },
  },
});

export const { reset, updatetesTimonyPage, updateCoverPic } =
  testimonySlices.actions;

export default testimonySlices.reducer;
