import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reset, updateFaqPage } from '../features/faq/faqSlices';
import { GetFaq } from '../features/faq/faqServicesSync';
import LoaderScreen from '../utils/loaderScreen';
import { NotFound } from './Styles/notFound';
import { DomPaginationState } from '../Components/DOM_Pagination_State';

const Faq = () => {
  const dispatch = useDispatch();

  const [showAnswer, setShowAnswer] = useState();

  const { faqArticleDetails, faqLoading, faqlimit, faqPage } = useSelector(
    (state) => state.faq
  );

  useEffect(() => {
    dispatch(GetFaq({ page: faqPage, limit: faqlimit }));

    return () => {
      dispatch(reset());
    };
  }, []);

  const nextPage = () => {
    if (!faqLoading) {
      if (faqArticleDetails?.hasNextPage) {
        dispatch(updateFaqPage(faqPage + 1));

        dispatch(
          GetFaq({
            page: faqPage + 1,
            limit: faqlimit,
          })
        );
      }
    }
  };

  const prevPage = () => {
    if (!faqLoading) {
      if (faqArticleDetails?.hasPrevPage) {
        dispatch(updateFaqPage(faqPage - 1));

        dispatch(
          GetFaq({
            page: faqPage - 1,
            limit: faqlimit,
          })
        );
      }
    }
  };

  return (
    <>
      <div class="stricky-header stricked-menu main-menu">
        <div class="sticky-header__content"></div>
      </div>

      <section class="page-header">
        <div
          class="page-header-bg"
          style={{
            backgroundImage: `url(/assets/images/backgrounds/page-header-bg.jpg)`,
          }}
        ></div>
        <div class="container">
          <div class="page-header__inner">
            <ul class="thm-breadcrumb list-unstyled">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>
                <span>/</span>
              </li>
              <li class="active">Pages</li>
            </ul>
            <h2>FAQs</h2>
          </div>
        </div>
      </section>

      <section class="feature-four">
        <div
          class="feature-four-shape"
          style={{
            backgroundImage: `url(/assets/images/shapes/feature-four-shape-1.png)`,
          }}
        ></div>
        <div class="container">
          <div class="feature-four__inner">
            {/* <div class="row">
              <div class="col-xl-4 col-lg-4">
                <div class="feature-four__single">
                  <div class="feature-four__icon">
                    <span class="icon-bonus"></span>
                  </div>
                  <div class="feature-four__content">
                    <h3 class="feature-four__title">
                      Payments <br />& donations
                    </h3>
                  </div>
                </div>
              </div>

              <div class="col-xl-4 col-lg-4">
                <div class="feature-four__single">
                  <div class="feature-four__icon">
                    <span class="icon-fingerprint-scan"></span>
                  </div>
                  <div class="feature-four__content">
                    <h3 class="feature-four__title">
                      Taxes & <br />
                      legal issues
                    </h3>
                  </div>
                </div>
              </div>

              <div class="col-xl-4 col-lg-4">
                <div class="feature-four__single">
                  <div class="feature-four__icon">
                    <span class="icon-account"></span>
                  </div>
                  <div class="feature-four__content">
                    <h3 class="feature-four__title">
                      Charity <br />
                      account help
                    </h3>
                  </div>
                </div>
              </div>
            </div> */}
            <div class="feature-four__search-box">
              <form class="feature-four__form">
                <div class="feature-four__form-input">
                  <input type="search" placeholder="Search here anything..." />
                  <button type="submit">
                    <i class="icon-magnifying-glass"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <section class="faq-page">
        <div class="container">
          <div class="section-title text-center">
            <span class="section-title__tagline">Do you need help?</span>
            <h2 class="section-title__title">
              We answers to all your <br />
              daily questions
            </h2>
          </div>
          <div class="row">
            <div class="col-xl-12 col-lg-12">
              <div class="faq-page__single">
                <div
                  class="accrodion-grp faq-one-accrodion"
                  data-grp-name="faq-one-accrodion-1"
                >
                  {faqLoading ? (
                    <LoaderScreen />
                  ) : faqArticleDetails?.docs?.length > 0 ? (
                    faqArticleDetails?.docs?.map((item, key) => (
                      <div
                        class="accrodion"
                        key={key}
                        onClick={() => {
                          if (showAnswer) {
                            setShowAnswer();
                          } else {
                            setShowAnswer(item?._id);
                          }
                        }}
                      >
                        <div class="accrodion-title">
                          <h4>{item?.question}</h4>
                        </div>
                        {showAnswer === item?._id && (
                          <div class="accrodion-content">
                            <div class="inner">
                              <p>{item?.answer}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    <NotFound>
                      <div className="not_found_wrapper">
                        <h3 class="team-one__name">
                          <a style={{ color: '#283734' }}>No Faq found</a>
                        </h3>
                      </div>
                    </NotFound>
                  )}
                </div>
              </div>
            </div>
          </div>

          <DomPaginationState
            onNext={nextPage}
            onPrev={prevPage}
            skip={faqArticleDetails?.page}
            loading={faqLoading}
            hasNext={faqArticleDetails?.hasNextPage}
            hasPrev={faqArticleDetails?.hasPrevPage}
          />
        </div>
      </section>
    </>
  );
};

export default Faq;
