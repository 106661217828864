import styled from "styled-components";

export const ArticleWrapper = styled.div`
  .content {
    .image {
      width: 100%;

      img {
        max-width: 100%;
        max-height: 30rem;
      }
    }

    img {
      max-width: 100%;
      max-height: 30rem;
    }
  }

  .articleWrapper__commentTextSectionWrapper {
    width: 100%;
    /* margin-top: 45px; */
    padding: 20px 0px;
    max-height: 700px;
    overflow-y: auto;

    &::-webkit-scrollbar-track {
      border-radius: 5px;
    }

    &::-webkit-scrollbar {
      width: 5px;
      height: 3px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #283734;
    }
  }
`;
