import { toast } from "react-toastify";
import APIs from "../apiUrl";

const getAlluserBlog = async (payload) => {
  try {
    const response = await APIs.get(
      `/get_enduser_blog/${payload.page}/${payload.limit}`
    );

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getSingleBlog = async (payload) => {
  try {
    const response = await APIs.get(`/get_single_post/${payload}`);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getBloggerComment = async (payload) => {
  try {
    const response = await APIs.get(`/single_post_comment/${payload}`);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const createComment = async (payload) => {
  try {
    const response = await APIs.post(`/comment_by_user/${payload.id}`, payload);

    if (response?.data?.message === "success") {
      toast("successfully commented");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const BlogServices = {
  getAlluserBlog,
  getSingleBlog,
  getBloggerComment,
  createComment,
};

export default BlogServices;
