import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import { NavLink } from 'react-router-dom';
import { GetTestimony } from '../features/testimony/testimonyServicesSync';
import { GetTeam } from '../features/team/teamServicesSync';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import PicStatus from '../Assets/avatar-profile-photo.png';
import { GetSponsors } from '../features/sponsor/sponsorServicesSync';
import { GetFaq } from '../features/faq/faqServicesSync';
import { NotFound } from './Styles/notFound';

function AboutPage() {
  const dispatch = useDispatch();

  const {
    testimonyArticleDetails,
    testimonyLoading,
    testimonylimit,
    testimonyPage,
  } = useSelector((state) => state.testimony);

  const { teamArticleDetails, teamLoading, teamlimit, teamPage } = useSelector(
    (state) => state.team
  );

  const { allSponsor, sponsorLoader } = useSelector((state) => state.sponsor);

  const { faqArticleDetails, faqLoading } = useSelector((state) => state.faq);

  useEffect(() => {
    Promise.all([
      dispatch(GetTestimony({ page: testimonyPage, limit: testimonylimit })),
      dispatch(GetTeam({ page: teamPage, limit: teamlimit })),
      dispatch(GetSponsors()),
      dispatch(GetFaq({ page: 1, limit: 3 })),
    ]);
  }, []);

  return (
    <>
      <div class="stricky-header stricked-menu main-menu">
        <div class="sticky-header__content"></div>
      </div>

      <section class="page-header">
        <div
          class="page-header-bg"
          style={{
            backgroundImage:
              'url(assets/images/backgrounds/page-header-bg.jpg)',
          }}
        ></div>
        <div class="container">
          <div class="page-header__inner">
            <ul class="thm-breadcrumb list-unstyled">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>
                <span>/</span>
              </li>
              <li class="active">Pages</li>
            </ul>
            <h2>About us</h2>
          </div>
        </div>
      </section>

      <section class="about-four">
        <div class="container">
          <div class="row">
            <div class="col-xl-6">
              <div class="about-four__left">
                <div class="about-four__img-box">
                  <div class="about-four__img">
                    <img
                      src="assets/images/resources/about-four-img-1.jpg"
                      alt=""
                    />
                  </div>
                  <div class="about-four__img-two">
                    <img
                      src="assets/images/resources/about-four-img-2.jpg"
                      alt=""
                    />
                  </div>
                  <div class="about-four__border"></div>
                </div>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="about-four__right">
                <div class="section-title text-left">
                  <span class="section-title__tagline">About ICTHARAE</span>
                  <h2 class="section-title__title">
                    Get to know about our ICTHARAE organization
                  </h2>
                </div>
                <p class="about-four__text">
                  International Centre for Total Health and Rights Advocacy
                  Empowerment (ICTHARAE) is on a mission is to advocate,
                  propagate and resonate the rights of disadvantaged and
                  marginalized persons, to legal, health and social services
                  through education, training and empowerment” irrespective of
                  religion, culture, ethnic group and Sexual orientation.
                </p>
                {/* <ul class="list-unstyled about-four__points">
                  <li>
                    <div class="icon">
                      <i class="fa fa-check"></i>
                    </div>
                    <div class="text">
                      <p>Proin sed magna vel mi suscipit commodo.</p>
                    </div>
                  </li>
                  <li>
                    <div class="icon">
                      <i class="fa fa-check"></i>
                    </div>
                    <div class="text">
                      <p>Cras aliquet nulla ut varius blandit.</p>
                    </div>
                  </li>
                  <li>
                    <div class="icon">
                      <i class="fa fa-check"></i>
                    </div>
                    <div class="text">
                      <p>Nulla auctor ipsum sed nisi lis porttitor.</p>
                    </div>
                  </li>
                </ul> */}
                <div class="about-four__btn-box">
                  {/* <NavLink to={'/about'}>
                    <a class="thm-btn about-four__btn">Discover More</a>
                  </NavLink> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="testimonial-two">
        <div class="container">
          <div class="section-title text-center">
            <span class="section-title__tagline">Our testimonials</span>
            <h2 class="section-title__title">
              What they’re talking <br /> about ICTHARAE
            </h2>
          </div>
          <div class="thm-swiper__slider swiper-container">
            {testimonyLoading ? (
              <SkeletonTheme baseColor="#2A292933" highlightColor="#444">
                <div>
                  <Skeleton width="100vw" height="30rem" />
                </div>
              </SkeletonTheme>
            ) : (
              <Swiper
                loop={true}
                autoplay={{
                  delay: 5000,
                }}
                spaceBetween={30}
                speed={500}
                breakpoints={{
                  0: {
                    spaceBetween: 30,
                    slidesPerView: 1,
                  },
                  375: {
                    spaceBetween: 30,
                    slidesPerView: 1,
                  },
                  575: {
                    spaceBetween: 30,
                    slidesPerView: 2,
                  },
                  768: {
                    spaceBetween: 30,
                    slidesPerView: 2,
                  },
                  992: {
                    spaceBetween: 30,
                    slidesPerView: 2,
                  },
                  1200: { spaceBetween: 30, slidesPerView: 3 },
                }}
                modules={[Pagination, Navigation, Autoplay]}
                class="swiper-wrapper"
              >
                {testimonyArticleDetails?.docs?.map((item, key) => (
                  <SwiperSlide class="swiper-slide" key={key}>
                    <div class="testimonial-two__single">
                      <div class="testimonial-two__img">
                        <img
                          src={
                            item?.cover_pic ? `${item?.cover_pic}` : PicStatus
                          }
                          alt=""
                        />
                      </div>
                      <div class="testimonial-two__content">
                        <div
                          class="testimonial-two__shape-1"
                          style={{
                            backgroundImage:
                              'url(assets/images/shapes/testimonial-two-shape-1.png)',
                          }}
                        ></div>
                        <p class="testimonial-two__text">{item?.about}</p>
                        <div class="testimonial-two__client-info">
                          <h4 class="testimonial-two__client-name">{`${item?.firstname} ${item?.lastname}`}</h4>
                          <p class="testimonial-two__client-sub-title">
                            {item?.user_type}
                          </p>
                        </div>
                        <div class="testimonial-two__rating">
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        </div>
      </section>

      <section class="become-volunteer-one">
        <div class="become-volunteer-one__bg-box">
          <div
            class="become-volunteer-one__bg jarallax"
            data-jarallax
            data-speed="0.2"
            data-imgPosition="50% 0%"
            style={{
              backgroundImage:
                'url(assets/images/backgrounds/become-volunteer-one-bg.jpg)',
            }}
          ></div>
        </div>
        <div
          class="become-volunteer-one__shape-1"
          style={{
            backgroundImage:
              'url(assets/images/shapes/become-volunteer-shape-1.png)',
          }}
        ></div>
        <div class="container">
          <div class="become-volunteer-one__inner">
            <p class="become-volunteer-one__sub-title">Become a Volunteers</p>
            <h3 class="become-volunteer-one__title">
              Join your hand with us for a <br /> better life and future
            </h3>
            <div class="become-volunteer-one__btn-box">
              <NavLink to={'/be_a_volunteer'}>
                <a
                  // href="become-volunteer.html"
                  class="thm-btn become-volunteer-one__btn"
                >
                  Discover More
                </a>
              </NavLink>
            </div>
          </div>
        </div>
      </section>

      <section class="faq-one faq-two">
        <div
          class="faq-one-shape-1"
          style={{
            backgroundImage: 'url(assets/images/shapes/faq-one-shape-1.png)',
          }}
        ></div>
        <div
          class="faq-one-bg"
          style={{
            backgroundImage: 'url(assets/images/backgrounds/faq-one-bg.png)',
          }}
        ></div>
        <div class="container">
          <div class="row">
            <div class="col-xl-6 col-lg-6">
              <div class="faq-one__left">
                <div class="section-title text-left">
                  <span class="section-title__tagline">
                    Recently asked questions
                  </span>
                  <h2 class="section-title__title">
                    People are frequently asking some questions from us
                  </h2>
                </div>
                {/* <p class="faq-one__text-1">
                  Proactively procrastinate cross-platform results via extensive
                  ideas distinctively underwhelm enterprise. Compellingly
                  plagiarize value-added sources with inexpensive schemas.
                </p> */}
                <a href="/faq" class="thm-btn faq-one__btn">
                  Learn how to get help
                </a>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6">
              <div class="faq-one__right">
                <div class="accrodion-grp" data-grp-name="faq-one-accrodion">
                  {faqLoading ? (
                    <SkeletonTheme baseColor="#2A292933" highlightColor="#444">
                      <div>
                        <Skeleton width="100vw" height="15rem" />
                      </div>
                    </SkeletonTheme>
                  ) : faqArticleDetails?.docs?.length > 0 ? (
                    faqArticleDetails?.docs?.map((item, key) => (
                      <div class="accrodion active" key={key}>
                        <div class="accrodion-title">
                          <h4>{item?.question}</h4>
                        </div>
                        <div class="accrodion-content">
                          <div class="inner">
                            <p>{item?.answer}</p>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <NotFound>
                      <div className="not_found_wrapper">
                        <h3 class="team-one__name">
                          <a style={{ color: '#283734' }}>No Faq found</a>
                        </h3>
                      </div>
                    </NotFound>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="team-one">
        <div class="container">
          <div class="section-title text-center">
            <span class="section-title__tagline">Our expert team</span>
            <h2 class="section-title__title">
              Meet the team behind <br /> their success story
            </h2>
          </div>
          <div class="row">
            {teamLoading ? (
              <SkeletonTheme baseColor="#2A292933" highlightColor="#444">
                <div>
                  <Skeleton width="100vw" height="30rem" />
                </div>
              </SkeletonTheme>
            ) : (
              teamArticleDetails?.docs?.map((item, key) => (
                <div
                  class="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
                  data-wow-delay="100ms"
                >
                  <div class="team-one__single">
                    <div class="team-one__img">
                      <img
                        src={item?.cover_pic}
                        alt=""
                        className="teamImage"
                        style={{ objectFit: 'cover' }}
                      />
                      <div class="team-one__social">
                        <a href="#">
                          <i class="fab fa-facebook"></i>
                        </a>
                        <a href="#">
                          <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#">
                          <i class="fab fa-pinterest-p"></i>
                        </a>
                        <a href="#">
                          <i class="fab fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                    <div class="team-one__content">
                      <h3 class="team-one__name">
                        <a href="javascript:void(0);">{`${item?.firstname} ${item?.lastname}`}</a>
                      </h3>
                      <p class="team-one__sub-title">{`${item?.office}`}</p>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </section>

      <section class="brand-one brand-three">
        <div
          class="brand-two-shape-1"
          style={{
            backgroundImage:
              'url(assets/images/shapes/brand-three-shape-1.png)',
          }}
        ></div>
        <div class="container">
          <div class="thm-swiper__slider swiper-container">
            {sponsorLoader ? (
              <SkeletonTheme baseColor="#2A292933" highlightColor="#444">
                <div>
                  <Skeleton width="100vw" height="10rem" />
                </div>
              </SkeletonTheme>
            ) : (
              <Swiper
                spaceBetween={100}
                slidesPerView={5}
                //   speed={1500}
                autoplay={{
                  delay: 5000,
                }}
                loop={true}
                breakpoints={{
                  0: {
                    spaceBetween: 30,
                    slidesPerView: 2,
                  },
                  375: {
                    spaceBetween: 30,
                    slidesPerView: 2,
                  },
                  575: {
                    spaceBetween: 30,
                    slidesPerView: 3,
                  },
                  767: {
                    spaceBetween: 50,
                    slidesPerView: 4,
                  },
                  991: {
                    spaceBetween: 50,
                    slidesPerView: 5,
                  },
                  1199: {
                    spaceBetween: 100,
                    slidesPerView: 6,
                  },
                }}
                //   centeredSlides={true}
                modules={[Pagination, Navigation, Autoplay]}
                class="swiper-wrapper"
              >
                {allSponsor?.map((item, key) => (
                  <SwiperSlide class="swiper-slide">
                    <img
                      src={item?.cover_pic}
                      alt=""
                      style={{ height: '5rem' }}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutPage;
