import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { GetSingleCharity } from '../features/charity/charityServiceSync';
import HTMLReactParser from 'html-react-parser';
import { ArticleWrapper } from './Styles/aritcleWrapper.style';
import moment from 'moment';
import LoaderScreen from '../utils/loaderScreen';

const DonateDetails = () => {
  const dispatch = useDispatch();

  const { donate } = useParams();

  const { singleCharityDetails, charityLoading } = useSelector(
    (state) => state.charity
  );

  useEffect(() => {
    dispatch(GetSingleCharity(donate));
  }, [donate]);

  return (
    <>
      <ArticleWrapper>
        <section class="page-header">
          <div
            class="page-header-bg"
            style={{
              backgroundImage:
                'url(/assets/images/backgrounds/page-header-bg.jpg)',
            }}
          ></div>
          <div class="container">
            <div class="page-header__inner">
              <ul class="thm-breadcrumb list-unstyled">
                <li>
                  <a href="index.html">Home</a>
                </li>
                <li>
                  <span>/</span>
                </li>
                <li class="active">Donations details</li>
              </ul>
              {singleCharityDetails?.title && (
                <h2
                  style={{ textTransform: 'capitalize' }}
                >{`${singleCharityDetails?.title}`}</h2>
              )}
            </div>
          </div>
        </section>

        {charityLoading ? (
          <LoaderScreen />
        ) : (
          <section class="donation-details">
            <div class="container">
              <div class="row">
                <div class="col-xl-8 col-lg-7">
                  <div class="donation-details__left">
                    <div class="donation-details__top">
                      <div class="donation-details__img">
                        <img
                          src={`${singleCharityDetails?.cover_pic}`}
                          alt=""
                          style={{
                            maxHeight: '50rem',
                            minHeight: '30rem',
                            maxWidth: '100%',
                            objectPosition: 'center',
                            objectFit: 'cover',
                            borderRadius: '8px',
                          }}
                        />
                        <div class="donation-details__date">
                          <p>{`${singleCharityDetails?.hash_tag}`}</p>
                        </div>
                      </div>
                      <div class="donation-details__content">
                        <h3
                          class="donation-details__title"
                          style={{ textTransform: 'capitalize' }}
                        >
                          {`${singleCharityDetails?.title}`}
                        </h3>
                        <p class="donation-details__text content">
                          {HTMLReactParser(
                            HTMLReactParser(`${singleCharityDetails?.article}`)
                          )}
                        </p>
                      </div>
                    </div>
                    <div class="donation-details__donate">
                      <div
                        class="donation-details__donate-shape"
                        style={{
                          backgroundImage:
                            'url(assets/images/shapes/donation-details-donate-shape.png)',
                        }}
                      ></div>
                      <div class="donation-details__donate-left">
                        <ul class="list-unstyled donation-details__donate-list">
                          <li>
                            <div class="icon">
                              <span class="icon-solidarity"></span>
                            </div>
                            <div class="text">
                              <span>Love</span>
                            </div>
                          </li>
                          <li>
                            <div class="icon">
                              <span class="icon-target-1"></span>
                            </div>
                            <div class="text">
                              <span>Goal</span>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div class="donation-details__donate-btn">
                        <NavLink
                          to={`/donate_now/${singleCharityDetails?.title}`}
                        >
                          <a class="thm-btn"> Donate now</a>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-5">
                  <div class="donation-details__sidebar">
                    <div class="donation-details__organizer">
                      <div
                        class="sidebar-shape-1"
                        style={{
                          backgroundimage:
                            'url(assets/images/shapes/sidebar-shape-1.png)',
                        }}
                      ></div>
                      <div class="donation-details__organizer-img">
                        <img
                          src="assets/images/resources/donation-details-organizer-img.jpg"
                          alt=""
                        />
                      </div>
                      <div class="donation-details__organizer-content">
                        <p class="donation-details__organizer-date">
                          {`Created ${moment(singleCharityDetails?.date).format(
                            'DD MMM, YYYY'
                          )}`}
                        </p>

                        <ul class="list-unstyled donation-details__organizer-list">
                          <li>
                            <div class="icon">
                              <span class="fas fa-tag"></span>
                            </div>
                            <div class="text">
                              <p>{singleCharityDetails?.hash_tag}</p>
                            </div>
                          </li>
                          <li>
                            <div class="icon">
                              <span class="fas fa-map-marker-alt"></span>
                            </div>
                            <div class="text">
                              <p>{singleCharityDetails?.location}</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="donation-details__sidebar-shaare-cause">
                      <div
                        class="sidebar-shape-1"
                        style={{
                          backgroundimage:
                            'url(assets/images/shapes/sidebar-shape-1.png)',
                        }}
                      ></div>
                      <h3 class="donation-details__sidebar-shaare-cause-title">
                        Share
                      </h3>
                      <div class="donation-details__sidebar-shaare-cause-social">
                        <a href="#">
                          <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#">
                          <i class="fab fa-facebook"></i>
                        </a>
                        <a href="#">
                          <i class="fab fa-pinterest-p"></i>
                        </a>
                        <a href="#">
                          <i class="fab fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </ArticleWrapper>
    </>
  );
};

export default DonateDetails;
