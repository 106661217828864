import APIs from "../apiUrl";

const getAlluserResource = async (payload) => {
  try {
    const response = await APIs.get(
      `/get_enduser_resources/${payload.page}/${payload.limit}`
    );

    if (response?.data?.message === "success") {
      console.log(response.data, "filling thes apce");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getSingleResource = async (payload) => {
  try {
    const response = await APIs.get(`/get_single_resources/${payload}`);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const ResourceServices = {
  getSingleResource,
  getAlluserResource,
};

export default ResourceServices;
