import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { InitializeDonation } from '../features/donation/donationServicesSync';
import { BaseUrl } from '../utils/baseUrl';
import { InfinitySpin } from 'react-loader-spinner';
import { NavLink, useParams } from 'react-router-dom';

const baseSchema = Yup.object({
  currency: Yup.string().required(),
  email: Yup.string().email().required(),
  address: Yup.string().required(),
  firstname: Yup.string().required(),
  lastname: Yup.string().required(),
  callback_url: Yup.string().required(),
  amount: Yup.number()
    .required()
    .min(10, 'Amount must be greater than or equal to 10')
    .transform((value, originalValue) => {
      // Convert the string value to a number
      const numberValue = parseFloat(originalValue);
      // Return the number value or NaN if conversion fails
      return isNaN(numberValue) ? undefined : numberValue;
    })
    .typeError('Amount must be a number'),
});

function Donate_Now() {
  const { title } = useParams();

  const { donationLoading } = useSelector((state) => state.donation);

  const dispatch = useDispatch();

  const handleSubmited = async (value, { resetForm }) => {
    await dispatch(InitializeDonation({ ...value, title: title }));

    resetForm();
  };

  const { values, errors, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: {
        firstname: '',
        lastname: '',
        email: '',
        address: '',
        currency: 'NGN',
        amount: 0.0,
        callback_url: `${BaseUrl}/donate`,
      },
      validationSchema: baseSchema,
      onSubmit: handleSubmited,
    });

  const handleSubmitDetails = (e) => {
    e.preventDefault();

    handleSubmit();
  };

  console.log(values, 'visions');

  return (
    <>
      <section class="page-header">
        <div
          class="page-header-bg"
          style={{
            backgroundImage:
              'url(assets/images/backgrounds/page-header-bg.jpg)',
          }}
        ></div>
        <div class="container">
          <div class="page-header__inner">
            <ul class="thm-breadcrumb list-unstyled">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>
                <span>/</span>
              </li>
              <li class="active">Donate now</li>
            </ul>
            <h2>Donate now</h2>
          </div>
        </div>
      </section>
      <section class="donate-now">
        <div class="container">
          <div class="row">
            <div class="col-xl-8 col-lg-7">
              <div class="donate-now__left">
                <div class="donate-now__enter-donation">
                  <h3 class="donate-now__title">
                    Enter your donation Amount :
                  </h3>
                  <div class="donate-now__enter-donation-input">
                    <select
                      // class="selectpicker"
                      style={{
                        width: '20%',
                        height: '100%',
                        position: 'absolute',
                        backgroundColor: 'transparent',
                        outline: 'none',
                        border: 'none',
                        padding: '10px 13px',
                        fontSize: '18px',
                        color: '#fff',
                        fontWeight: '600',
                      }}
                      onChange={(e) => {
                        setFieldValue('currency', e.target.value);
                      }}
                    >
                      <option style={{ color: '#000' }} name={'currency'}>
                        NGN
                      </option>
                      <option style={{ color: '#000' }} name={'currency'}>
                        USD
                      </option>
                    </select>
                    <input
                      type="text"
                      name="amount"
                      value={values?.amount}
                      placeholder="0.00"
                      onChange={handleChange}
                    />
                  </div>
                  {errors.amount && (
                    <p className="is-danger" style={{ color: 'red' }}>
                      {errors.amount}
                    </p>
                  )}
                </div>
                <div class="donate-now__personal-info-box">
                  <h3 class="donate-now__title">Personal info</h3>
                  <form class="donate-now__personal-info-form">
                    <div class="row">
                      <div class="col-xl-6">
                        <div class="donate-now__personal-info-input">
                          <input
                            type="text"
                            placeholder="First name"
                            name="firstname"
                            value={values?.firstname}
                            onChange={handleChange}
                          />
                          {errors.firstname && (
                            <p className="is-danger" style={{ color: 'red' }}>
                              {errors.firstname}
                            </p>
                          )}
                        </div>
                      </div>
                      <div class="col-xl-6">
                        <div class="donate-now__personal-info-input">
                          <input
                            type="text"
                            placeholder="Last name"
                            name="lastname"
                            value={values?.lastname}
                            onChange={handleChange}
                          />
                          {errors.lastname && (
                            <p className="is-danger" style={{ color: 'red' }}>
                              {errors.lastname}
                            </p>
                          )}
                        </div>
                      </div>
                      <div class="col-xl-6">
                        <div class="donate-now__personal-info-input">
                          <input
                            type="email"
                            placeholder="Email address"
                            name="email"
                            value={values?.email}
                            onChange={handleChange}
                          />

                          {errors.email && (
                            <p className="is-danger" style={{ color: 'red' }}>
                              {errors.email}
                            </p>
                          )}
                        </div>
                      </div>
                      <div class="col-xl-6">
                        <div class="donate-now__personal-info-input">
                          <input
                            type="text"
                            placeholder="Address"
                            name="address"
                            value={values?.address}
                            onChange={handleChange}
                          />

                          {errors.address && (
                            <p className="is-danger" style={{ color: 'red' }}>
                              {errors.address}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="donate-now__payment-info">
                  {/* <form class="donate-now__payment-info-form">
                    <div class="donate-now__payment-info-btn-box"> */}
                  {donationLoading ? (
                    <InfinitySpin width="150" color="#0716A5" />
                  ) : (
                    <button
                      onClick={handleSubmitDetails}
                      type="button"
                      class="thm-btn donate-now__payment-info-btn"
                    >
                      Donate now
                    </button>
                  )}
                  {/* </div>
                  </form> */}
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-5">
              <div class="donate-now__right">
                <div class="donation-details__sidebar-shaare-cause">
                  <div
                    class="sidebar-shape-1"
                    style={{
                      backgroundimage:
                        'url(assets/images/shapes/sidebar-shape-1.png)',
                    }}
                  ></div>
                  <h3 class="donation-details__sidebar-shaare-cause-title">
                    Share
                  </h3>
                  <div class="donation-details__sidebar-shaare-cause-social">
                    <a href="#">
                      <i class="fab fa-twitter"></i>
                    </a>
                    <a href="#">
                      <i class="fab fa-facebook"></i>
                    </a>
                    <a href="#">
                      <i class="fab fa-pinterest-p"></i>
                    </a>
                    <a href="#">
                      <i class="fab fa-instagram"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Donate_Now;
